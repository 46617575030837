import React,  { useEffect, useState } from 'react'
import youtube from "../../services/youtube";
import moment from 'moment'

import FilAriane from '../layout/ariane';
moment.locale('fr')


function PublicationsVideos() {
    const [nextToken, setNextToken] = useState('')
    const [prevToken, setPrevToken] = useState('')
    const [moduleVideos, setModuleVideos] = useState([])

    useEffect(() => {
        youtube.get('/search', {
            params: {
                pageToken: nextToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
        })
    })


    const getNextToken = () => {
        youtube.get('/search', {
            params: {
                pageToken: nextToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setNextToken(res.data.nextPageToken)
        })
    }

    const getPrevToken = () => {
        youtube.get('/search', {
            params: {
                pageToken: prevToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setPrevToken(res.data.prevPageToken)
        })
    }

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'VIDEOS' }
    }
    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Vidéos</h2>
                    <FilAriane ariane={ariane} />

                </div>


            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">

                    <div className="x-scroll">
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => getPrevToken()} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>

                                <li className="page-item">
                                    <a onClick={() => getNextToken()} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div>
                </div>
                {/* <div className="row mt-3">
                    <div className="col-sm-12">
                    <ul className="gallery caption-1 mt-4">
                        {
                            moduleVideos.map(module => (
                                <li>
                                    <figure>
                                        <a href={`https://www.youtube.com/watch?v=${module.id.videoId}`}>
                                            <img src={module.snippet.thumbnails.medium.url}
                                                alt="" />
                                            <figcaption>
                                                <h1>{module.snippet.title}</h1>
                                                <p>Par: {module.snippet.channelTitle}</p>
                                            </figcaption>
                                        </a>
                                    </figure>
                                </li>))
                        }

                    </ul>
                    </div>
                </div> */}
                <div class="row mt-3">
                    {
                        moduleVideos.map(module => (
                        <div class="col-lg-6 col-sm-12 " key={module.id.videoId} style={{marginBottom:'20px'}}>
                            <div class="single-teachers mt-30 text-center">
                                <div class="image">
                                    <iframe className="styles"
                                        src={`https://www.youtube.com/embed/${module.id.videoId}`}>
                                    </iframe>
                                </div>
                                <div class="cont">
                                    <a className="cursor text-light" href={`https://www.youtube.com/watch?v=${module.id.videoId}`}>{module.snippet.title}</a>
                                </div>
                            </div>
                        </div>))
                    }
                </div>
            </div>
        </div>
    )
}

export default PublicationsVideos;