import React, { useState, useEffect } from 'react';
import { getTokenContribuableInfos } from '../../services/aib';
import ReactLoading from 'react-loading';
import FilAriane from '../layout/ariane';

function RechercheIfu(props) {

    const [search, setSearch] = useState("")
    const [contribuable, setContribuable] = useState(null)
    const [searching, setSearching] = useState(false)

    const changeSearch = ifu => {
        setSearch(ifu)
    }

    const searchContribuable = query => {
        setSearching(true)
        getTokenContribuableInfos(query).then(res => {
            setSearching(false)
            if (res.data.success) {
                setContribuable(res.data.object)
            }
        }).catch(err => {
            setSearching(false)
            setContribuable(null)

        })

    }

    useEffect(() => {
        if (search.length == 13) {
            searchContribuable(search)
        } else {
            setContribuable(null)
            setSearching(false)

        }
    }, [search])

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'Rechercher un contribuable' }
    }


    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info mb-5">
                    <h2 className="text-center text-white">Rechercher un contribuable</h2>

                    <FilAriane ariane={ariane}/>
                </div>


            </div>

            <div className="container mb-5 mt-3 ">

                <div className="row">
                    <div className="searchContainer-simple mb-5">
                        <div className="search open" >
                            <div className="icon" title="Rechercher un contribuable">
                                <span>
                                    <svg viewBox="0 0 40 40">
                                        <path d="M3,3 L37,37"></path>
                                    </svg>
                                </span>
                            </div>
                            <div className="field ">
                                <input type="text" placeholder="Numéro IFU ..." value={search} onChange={(e) => changeSearch(e.target.value)} />

                            </div>
                            {searching && <ReactLoading type="cylon" color="#138a92" height={'8%'} width={'8%'} />
                            }
                        </div>

                    </div>



                    {search.length == 13 && !contribuable && !searching && <div className="card searchResultIfu" style={{ display: 'block' }}>

                        <div className="list-group">
                            <div className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">Aucun contribuable disponible avec l'IFU</h5>
                                </div>
                                <p className="mb-1">{search}</p>
                                <small className="badge bg-danger rounded-pill p-2">INEXISTANT</small>
                            </div>

                        </div>

                    </div>}



                    {search.length == 13 && contribuable && !searching && <div className="card searchResultIfu" style={{ display: 'block' }}>
                        {contribuable && <h5 className="mt-2 mb-2 textPrimaryColor">Identification du contribuable</h5>}
                        <div className="list-group p-0">
                            <div className=" " aria-current="true">
                                <p className="d-flex w-100 justify-content-between  list-group-item p-4  ">
                                    <h5 className="mb-1">Numéro IFU </h5>
                                    <small>{search} </small>
                                </p>

                                {contribuable.taxPayer && <p className="d-flex w-100 justify-content-between   list-group-item p-4 ">
                                    <h5 className="mb-1">Raison Sociale </h5>
                                    <small className="center">{contribuable.taxPayer.raisonSociale ? contribuable.taxPayer.raisonSociale : "-"}</small>
                                </p>}


                                {contribuable.taxPayer && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">Sigle </h5>
                                    <small className="center">{contribuable.taxPayer.nomCourt ? contribuable.taxPayer.nomCourt : "-"}</small>
                                </p>}

                                {contribuable.taxPayer && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">Nom </h5>
                                    <small className="center">{contribuable.taxPayer.nom ? contribuable.taxPayer.nom : "-"}</small>
                                </p>}

                                {contribuable.taxPayer && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">Prénom </h5>
                                    <small className="center">{contribuable.taxPayer.prenom ? contribuable.taxPayer.prenom : "-"}</small>
                                </p>}

                                {contribuable.taxPayer && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">Centre d'impôt </h5>
                                    <small className="center">{contribuable.taxPayer.centre ? contribuable.taxPayer.centre : "-"}</small>
                                </p>}

                                {contribuable.taxPayer && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">Département </h5>
                                    <small className="center">{contribuable.taxPayer.departement ? contribuable.taxPayer.departement : "-"}</small>
                                </p>}



                            </div>

                        </div>

                        {contribuable && <h5 className="mt-4 mb-2 textPrimaryColor"> Statut du contribuable</h5>}
                        <div className="list-group p-0">
                            <div className=" " aria-current="true">

                                {contribuable && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">Statut CNF  </h5>
                                    {contribuable.contStatutCnfExplicit == "DESACTIVE" && <small className={`badge bg-danger rounded-pill p-2`}>INACTIF</small>}
                                    {contribuable.contStatutCnfExplicit == "ACTIF" && <small className={`badge bg-success rounded-pill p-2`}>ACTIF</small>}
                                    {!contribuable.contStatutCnfExplicit && <small className={`center`}>-</small>}

                                </p>}

                                {contribuable && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">Statut TVA  </h5>
                                    {contribuable.contStatutTvaExplicit == "NON ASSUJETTI" && <small className={`badge bg-danger rounded-pill p-2`}>NON ASSUJETTI</small>}
                                    {contribuable.contStatutTvaExplicit == "ASSUJETTI" && <small className={`badge bg-success rounded-pill p-2`}>ASSUJETTI</small>}
                                    {!contribuable.contStatutTvaExplicit && <small className={`center`}>-</small>}

                                </p>}

                                {contribuable && <p className="d-flex w-100 justify-content-between   list-group-item p-4">
                                    <h5 className="mb-1">AIB </h5>
                                    {contribuable.contAibRate && <small >{contribuable.contAibRate * 100} %</small>}
                                    {!contribuable.contAibRate && <small className={`center`}>-</small>}

                                </p>}



                            </div>

                        </div>
                    </div>}
                </div>
            </div>
        </div>

    )
}

export default RechercheIfu;