import './App.css';
import MainLayout from './components/layout';
import Home from './components/home';
import ReactGA from 'react-ga';
import React from 'react';
import HelmetMetaData from './constants/header'
function App() {

  React.useEffect(() => {
    ReactGA.initialize('UA-200325115-1')
    ReactGA.pageview(window.location.pathname + window.location.search)

  }, [window.location]);
  return (
    <MainLayout>
      <HelmetMetaData></HelmetMetaData>
      <Home />
    </MainLayout>
  );
}

export default App;
