import React, { createContext, Fragment, useEffect, useState } from 'react';
import { getResource } from '../../services/api';
import Footer from './footer';
import Header from './header';
import Loading from './loading';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from '../home';
import Page from '../page';
import Documents from '../document';
import Centre from '../centre';
import Publications from '../publications';
import PublicationsActualites from '../publications/actualites';
import PublicationsAvisCommuniques from '../publications/avis_communiques';
import Faq from '../faq';
import Recherche from '../recherche';
import Statistique from '../statistique';
import MenuCenter from '../menuCenter';
import RechercheIfu from '../search_ifu';
import ActualiteItem from '../publication/actualite_item';
import CommuniqueItem from '../publication/communique_item';
import Reformes from '../publications/reformes';
import PlanSite from '../page/plan_site';
import ServicesUsuels from '../services_usuels';
import Echeances from '../echeance_fiscales';
import PublicationsVideos from '../publications/videos';
import Contact from '../contact';
import Plan from '../plan';
import Regime from '../regime';
import Contribuable from '../contribuable';
import Plainte from '../plainte';
import Sondage from '../sondage';
import { getTokenContribuable } from '../../services/aib';
import Cookies from 'js-cookie';
import ScrollToTop from '../../lib/scrolltotop';


export const AppContext = createContext()



const MainLayout = (props) => {
    const [layout, setLayout] = useState(null)
    const [menus, setMenus] = useState(null)
    const [appState, setAppState] = useState(INITIAL_APP_STATE)


    useEffect(() => {
        loadMenu()

        getResource(`layout`).then(res => {
            setLayout(res.data)
        })
        const token_aib = Cookies.get('token_aib')
        if (!token_aib || token_aib == undefined)
            getTokenContribuable()
    }, [])

    const loadMenu = () => {
        getResource(`menus?pagination=false&isPrincipal=true`).then(res => {
            const datas = res.data['hydra:member']
            setMenus(datas.filter(m => m.parent == null))
        })
    }

    return (
        <AppContext.Provider value={{ appState, setAppState }}>

            <Router>
                <Fragment>
                    <ScrollToTop />

                    {!layout || !menus && <Loading />}
                    {layout && menus &&
                        <div>
                            <Header layout={layout} menus={menus} />
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/page/:parent/:slug" component={Page} />
                                <Route path="/page/:slug" component={Page} />
                                <Route path="/documentations/:parent/:slug" component={Documents} />
                                <Route path="/documentations_thematiques/:parent/:slug" component={MenuCenter} />
                                <Route path="/publications/:parent/:slug" component={Publications} />
                                <Route path="/centres/:localite" component={Centre} />
                                <Route path="/centres" component={Centre} />
                                <Route path="/actualites/:slug" component={ActualiteItem} />
                                <Route path="/actualites" component={PublicationsActualites} />
                                <Route path="/reformes/:slug" component={CommuniqueItem} />
                                <Route path="/reformes" component={Reformes} />
                                <Route path="/communiques/:slug" component={CommuniqueItem} />
                                <Route path="/communiques" component={PublicationsAvisCommuniques} />
                                <Route path="/foire_aux_questions/:slug/:question" component={Faq} />
                                <Route path="/foire_aux_questions/:slug" component={Faq} />
                                <Route path="/foire_aux_questions" component={Faq} />
                                <Route path="/statistique_reforme" component={Statistique} />
                                <Route path="/messager_impot" component={Statistique} />
                                <Route path="/recherche/:query" component={Recherche} />
                                <Route path="/recherche_ifu" component={RechercheIfu} />
                                <Route path="/page/plansite" component={PlanSite} />
                                <Route path="/plan" component={Plan} />
                                <Route path="/services_usuels" component={ServicesUsuels} />
                                <Route path="/echeance_fiscales" component={Echeances} />
                                <Route path="/videos" component={PublicationsVideos} />
                                <Route path="/contactez-nous" component={Contact} />
                                <Route path="/contribuable/:slug" component={Contribuable} />
                                {/* <Route path="/regime" component={Regime} /> */}
                                <Route path="/deposer-plainte" component={Plainte} />
                                <Route path="/regime/:slug" exact component={Regime} />
                                <Route path="/sondage/:slug" exact component={Sondage} />


                            </Switch>
                            <Footer layout={layout} />
                        </div>}
                </Fragment>

            </Router>

        </AppContext.Provider>



    );
}

export default MainLayout;
const INITIAL_APP_STATE = { parent_menus: null }