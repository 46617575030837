import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function HelmetMetaData(props) {
    let location = useLocation();
    let currentUrl = "https://impots.bj" + location.pathname;
    let quote = props.quote !== undefined ? props.quote : "";
    let title = props.title !== undefined ? props.title : "Direction Générale des Impôts - Bénin";
    let image = props.image !== undefined ? props.image : "https://impots.bj/img/cori.png";
    let description = props.description !== undefined ? props.description : "La Direction Générale des Impôts (DGI) poursuit inlassablement la modernisation de ses services et la simplification de ses procédures. Dans ce cadre, ...";
    let hashtag = props.hashtag !== undefined ? props.hashtag : "#impotsbj";
    return (
        <Helmet>
            <title>{title}</title>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="Keywords" content="impôts au Bénin, DGI Benin, Gouv bj, finance bénin, contribuable bénin" />
            <meta name="csrf_token" content="" />
            <meta property="type" content="website" />
            <meta property="url" content={currentUrl} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="/logo192.png" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="_token" content="" />
            <meta name="Subject" content="Direction Générale des Impôts (DGI) " />
            <meta name="Language" content="fr" />
            <meta name="Robots" content="INDEX,FOLLOW" />
            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="og:image" content={image} />
            <meta property="twitter:image" content={image} />
            <meta property="twitter:image:src" content={image} />
            <meta property="twitter:card" content="summary" />
            <meta property="og:site_name" content="Direction Générale des Impôts (DGI) " />
            <meta property="twitter:url" content={currentUrl} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:description" content={description} />
            <meta http-equiv='cache-control' content='no-cache' />
            <meta http-equiv='expires' content='0' />
            <meta http-equiv='pragma' content='no-cache' />
        </Helmet>
    );
}