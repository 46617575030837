import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';
import FilAriane from '../layout/ariane';

function Plan() {
    const [page, setPage] = useState('');
    const [parent_menu, setParentMenu] = useState('');
    const [links, setLinks] = useState([]);
    const [weblinks, setWebLinks] = useState([]);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)

    useEffect(() => {
        /** Get Menus Primary Link */
        getResource(`menus?pagination=false&order[position]=ASC`).then(res => {
            setLinks(res.data['hydra:member'].filter(d => d.enfants.length > 0))
        })

        getResource(`home`).then(res => {
            const data_links = [
                {parent:"Regimes ficaux", enfants : res.data.regimes},
                {parent:"Services aux usagers", enfants : res.data.usage_services},
                {parent:"Services usuels", enfants : res.data.usual_services},
            ]
                
            setWebLinks(data_links)
        })

        /** Get Weblink  */
    }, []);


    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'PLAN DU SITE' }
    }


    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Plan du site</h2>
                    <FilAriane ariane={ariane} />
                </div>


            </div>
            <div className="container mb-3 mt-3 bg-white pb-5">

                <div className="row" style={{ position: 'relative' }}>

                    {
                        links.map(m => (
                            <div key={m.name} className="col-md-3 pt-4 mt-3 ">
                                <div className=" p-2 ">
                                    <h5 className="title">{m.name}</h5>
                                    <ul className="planDS">
                                        {(m.enfants && m.enfants.map(m1 => (
                                            <li key={m1.name}><a href={urlGenerator(m1)}>{m1.name}</a></li>
                                        )))}
                                    </ul>
                                </div>
                            </div>
                        ))
                    }

{
                        weblinks.map(m => (
                            <div key={m.parent} className="col-md-3 pt-4 mt-3 ">
                                <div className=" p-2 ">
                                    <h5 className="title">{m.parent}</h5>
                                    <ul className="planDS">
                                        {(m.enfants && m.enfants.map(m1 => (
                                            <li key={m1.libelle}><a href={urlGenerator(m1)}>{m1.libelle}</a></li>
                                        )))}
                                    </ul>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    )
}

export default Plan;