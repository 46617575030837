import React, { useState, useEffect } from 'react';
import { getResource, postResource } from '../../services/api';
import FilAriane from '../layout/ariane';
import _ from 'lodash'

function Plainte() {
    const [type_avis, setTypeAvis] = useState([]);
    const [services, setServices] = useState([]);
    const [datas, setDatas] = useState(INITIALIZE_DATAS);
    const [error, setError] = useState(false);
    const [successMsg, setSuccessMessage] = useState(false);
    const [layout, setLayout] = useState(null)

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'Plaintes et suggestions' }
    }
    const requiredMsg = "Ce champ est obligatoire"
    const emailErrorMsg = "Le format de l'email n'est pas correct"

    const changeNom = (event) => {
        setDatas({ ...datas, nom: event.target.value })

    }


    const changePrenom = (event) => {
        setDatas({ ...datas, prenom: event.target.value })
    }

    const changeTel = (event) => {
        setDatas({ ...datas, numTel: event.target.value })
    }

    const changeEmail = (event) => {
        setDatas({ ...datas, email: event.target.value })
    }

    const changeContenu = (event) => {
        setDatas({ ...datas, contenu: event.target.value })
    }

    const changeSujet = (event) => {
        setDatas({ ...datas, typeAvis: event.target.value })
    }


    const changeService = (event) => {
        setDatas({ ...datas, service: event.target.value })
    }

    const sendDatas = () => {
        if (checkErrorForm()) {
            setError(true)
        } else {
            let post = { ...datas, typeAvis: `api/type_avis/${datas.typeAvis}`, service: `api/services/${datas.service}` }
         
            postResource('avis', post).then(res => {
                setSuccessMessage(true)
                setDatas(INITIALIZE_DATAS)
                setError(false)
            })
        }
    }

    const checkErrorForm = () => {
        if (datas.nom === "") return true
        if (datas.prenom === "") return true
        if (!validateEmail(datas.email)) return true
        if (datas.typeAvis === "") return true
        if (datas.service === "") return true
        if (datas.contenu === "") return true

        return false
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    useEffect(() => {
        getResource(`type_avis?pagination=false`).then(res => {
            setTypeAvis(res.data['hydra:member'])
        })

        getResource(`services?pagination=false`).then(res => {
            setServices(res.data['hydra:member'])
        })
        getResource(`layout`).then(res => {
            setLayout(res.data)
        })
    }, []);

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Plaintes et suggestions</h2>
                    <FilAriane ariane={ariane} />
                </div>
            </div>

            <div className="container mb-3 mt-3">
                <div className="row">

                    {!successMsg && <div className="col-sm-12 col-lg-8 p-0 ps-4">
                        <div className="bg-light card-body ps-4 rounded dgishadow">
                            <h3 className="text-secondary mb-3">Déposer votre plainte ou suggestion ici</h3>
                            <div className="row mb-3">

                                <div className="col-md-6 mt-3">
                                    <div className="form-group">
                                        <span>Nom <small className="fw-bold">[obligatoire]</small></span>
                                        <input name="nom" value={datas.nom} onChange={(event) => changeNom(event)} type="text" className="form-control" />
                                        {error && datas.nom == "" && <small className="text-danger ">{requiredMsg}</small>}
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="form-group">
                                        <span>Prénom(s) <small className="fw-bold">[obligatoire]</small></span>
                                        <input value={datas.prenom} onChange={(event) => changePrenom(event)} type="text" className="form-control" />
                                        {error && datas.prenom == "" && <small className="text-danger ">{requiredMsg}</small>}

                                    </div>
                                </div>

                                <div className="col-md-12 mt-3">
                                    <div className="form-group">
                                        <span>Société/Entreprise <small className="fw-bold">[obligatoire]</small></span>
                                        <input name="nom" value={datas.nom} onChange={(event) => changeNom(event)} type="text" className="form-control" />
                                        {error && datas.nom == "" && <small className="text-danger ">{requiredMsg}</small>}
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="form-group">
                                        <span>Numero Téléphone <small className="fw-bold">[optionnel]</small></span>
                                        <input value={datas.numTel} onChange={(event) => changeTel(event)} placeholder="+22990000000" type="text" className="form-control" />
                                        {/* {error && datas.numTel == "" && <small className="text-danger ">{requiredMsg}</small>} */}

                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="form-group">
                                        <span>Email <small className="fw-bold">[obligatoire]</small></span>
                                        <input value={datas.email} type="email" placeholder='aaaa@domain.com' onChange={(event) => changeEmail(event)} className="form-control" />
                                        {error && validateEmail(datas.email) == "" && <small className="text-danger ">{emailErrorMsg}</small>}

                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="form-group">
                                    <span>Sujet <small className="fw-bold">[obligatoire]</small></span>
                                    <select  className="form-control" value={datas.typeAvis} onChange={(event) => changeSujet(event)} >
                                        <option value=""  disabled>Choisir un sujet</option>
                                        {
                                            type_avis.map(sujet => (
                                                <option key={sujet.id} value={sujet.id}>{sujet.libelle}</option>
                                            ))
                                        }
                                    </select>
                                    {error && datas.typeAvis == "" && <small className="text-danger ">{requiredMsg}</small>}

                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="form-group">
                                    <span>Service concerné <small className="fw-bold">[obligatoire]</small></span>
                                    <select className="form-control" value={datas.service} onChange={(event) => changeService(event)} >
                                        <option value=""  disabled>Choisir un service</option>
                                        {
                                            _.sortBy(services.filter(s=>s.libelle!="Autres"), ['libelle']).map(service => (
                                                <option key={service.id} value={service.id}>{service.libelle}</option>
                                            ))
                                        }
                                                       {
                                            _.sortBy(services.filter(s=>s.libelle=="Autres"), ['libelle']).map(service => (
                                                <option key={service.id} value={service.id}>{service.libelle}</option>
                                            ))
                                        }
                                    </select>
                                    {error && datas.service == "" && <small className="text-danger ">{requiredMsg}</small>}

                                </div>
                            </div>

                            {/* <div class="row mb-3">
                                <label for="telephone" class="col-sm-12 col-md-4 col-form-label"
                                    title="Vous pouvez faire une sélection multiple en utilisant les touches Ctrl ou Maj. Vous pouvez télécharger des fichiers supplémentaires en parcourant plusieurs fois.">Sélectionnez
                                    les fichiers à joindre <span class="text-danger">*</span></label>
                                <div class="col-sm-12 col-md-8">
                                    <input type="file" class="form-control" id="fichier" />
                                    <small class="tiny-text">La taille limite pour chaque fichier est de 100
                                        MB</small>

                                </div>
                            </div> */}

                            <div className="mb-3">
                                <div className="form-group">
                                    <span>Message <small className="fw-bold">[obligatoire]</small></span>
                                    <textarea value={datas.contenu} onChange={(event) => changeContenu(event)} cols="30" rows="5" className="form-control"></textarea>
                                    {error && datas.contenu == "" && <small className="text-danger ">{requiredMsg}</small>}

                                </div>
                            </div>
                            <div className="d-flex">

                                <button type="submit" className="btn btn-warning mx-auto d-block" onClick={() => { sendDatas() }}><i
                                    className="fas fa-paper-plane"></i>
                                    Envoyer le message
                                </button>
                            </div>
                        </div>
                    </div>
                    }

{  !successMsg &&                  <div className="col-sm-12  col-lg-4 p-0">
                        <div className="contact-bg-info-content dgishadow">
                            <div className="contact-bg-info mt-5">
                                <h4 className="text-center text-white">Plaintes et suggestions</h4>
                                {layout && layout.parametres.filter(p => p.libUnique == "contact_message").length > 0 && <h4 className="text-white mb-3">{layout.parametres.filter(p => p.libUnique == "contact_message")[0].valeur}</h4>}

                                {layout && layout.parametres.filter(p => p.libUnique == "contact_email").length > 0 &&
                                    <h4 className="text-white"><i className="fas fa-assistive-listening-systems"
                                        style={{ fontSize: 29 }}></i> <b>
                                            {layout.parametres.filter(p => p.libUnique == "contact_email")[0].valeur}
                                        </b></h4>}
                                {layout && layout.parametres.filter(p => p.libUnique == "contact_phone").length > 0 && <button
                                    className="btn btn-outline-warning mt-3 mb-4 waves-effect waves-light numvertbtn text-center">
                                    <span className="numvert"> <i className="fas fa-phone-alt"></i> <b>Nº</b>
                                        <small>Vert</small></span><span className="numeroVert"> {layout.parametres.filter(p => p.libUnique == "contact_phone")[0].valeur}
                                    </span>
                                </button>}
                            </div>
                        </div>
                        <div className="contact-bg-image"></div>

                    </div>}
                    {successMsg && <div className="col-sm-12 col-lg-8 p-0 ps-4 pb-50" >
                        <div className="bg-light card-body ps-4 rounded dgishadow" style={{ minHeight: 300 }}>
                            <h3 className="text-success mb-3">Votre message est envoyé</h3>
                            <p> Nous vous remercions pour votre message ! Un agent vous donnera une réponse très rapidement.</p>
                        </div>
                    </div>}
                </div>
            </div>

        </div>
    )
}

export default Plainte;

const INITIALIZE_DATAS = {
    nom: '',
    prenom: '',
    email: '',
    numTel: '',
    autreService: '',
    contenu: '',
    typeAvis: '',
    service: ''
}
