import React, { useEffect, useState } from 'react'
import LinkUrl from '../../lib/link';
import Newstetter from './newsletter';

const Footer = ({ layout }) => {
    const [gaUsers, setGaUsers] = useState([])
    const [showNewsletterModal, setShowNewletersModal] = useState(false)

    useEffect(() => {
        window.gapi && window.gapi.client
            .init({
                discoveryDocs: [
                    "https://content.googleapis.com/discovery/v1/apis/analyticsreporting/v4/rest",
                ],
            })
            .then(() => {
                window.gapi.client.analyticsreporting.reports.batchGet({
                    "resource": {
                        "reportRequests": [
                            {
                                "viewId": '245488053',
                                "dateRanges": [
                                    {
                                        "startDate": "2021-06-01",
                                        "endDate": "today"
                                    }
                                ]
                            }
                        ]
                    }
                })
                    .then(response => {
                        setGaUsers(response.result.reports[0].data.totals[0].values);
                        //JSON.stringify(gaUsers);
                    },
                        function (err) { console.error("Execute error", err); });
            });
    }, [])

    const closeModal = () => {
        setShowNewletersModal(false)
    }

    return (
        <footer>
            <div className="container-fluid">
                <div className="row ms-3 pt-3">
                    <div className="col-sm-12 col-md-4 footer-color2 rounded pt-5 pb-5">
                        {/* <img src={'/img/logo.png'} alt="" className="d-block mx-auto footer-logo"/> */}
                        {layout.parametres.filter(p => p.libUnique == "logo" && p.valeur != "").length > 0 ? <LinkUrl className="" to="/"><img src={layout.parametres.filter(p => p.libUnique == "logo")[0].valeur} alt="" className="d-block mx-auto footer-logo" /></LinkUrl> : <LinkUrl className="" to="/"><img src={'/img/logo2.png'} alt="" className="d-block mx-auto footer-logo" /></LinkUrl>}

                        <p className="fw-bold text-light text-center mt-3">Suivez-nous sur</p>
                        <div className="social-media text-center">
                            {layout.parametres.filter(p => p.libUnique == "facebook_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "facebook_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-facebook"></i></a>}
                            {layout.parametres.filter(p => p.libUnique == "twitter_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "twitter_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-twitter-square"></i></a>}
                            {layout.parametres.filter(p => p.libUnique == "linkedin_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "linkedin_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-linkedin-in"></i></a>}
                            {layout.parametres.filter(p => p.libUnique == "youtube_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "youtube_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-youtube"></i></a>}
                            <a href="https://www.flickr.com/photos/186545521@N02/" style={{ color: '#fff' }}><i className="fab fa-flickr"></i></a>
                        </div>

                        <p className="text-white text-center">
                            <small>
                                Dernière mise à jour: Lundi 02 Juin 2023
                            </small> <br/>
                            <small>&copy; {new Date().getFullYear()}

                                {layout.parametres.filter(p => p.libUnique == "copyright").length > 0 &&
                                    <span> {`${layout.parametres.filter(p => p.libUnique == "copyright")[0].valeur} `} </span>}

                            </small>
                        </p>

                        {gaUsers > 0 &&
                            <div className="text-center">
                                {layout.parametres.filter(p => p.libUnique == "showVisiteurs" && p.valeur == 1).length > 0 && <button
                                    className="btn btn-outline-primary mt-3 mx-auto text-center mb-4 waves-effect waves-light visitcountbtn text-center">
                                    <span className="visitCount">  {gaUsers} </span>
                                    <span className="visitcount"> <i className="fas fa-eye"></i> <small>Visiteurs</small></span>
                                </button>}
                            </div>
                        }

                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="d-flex mb-4">
                            <div className="text-white p-2"><h3 className="text-white"><i className="fas fa-phone"></i></h3></div>
                            <div className="text-white">Appelez-nous
                                {layout && layout.parametres.filter(p => p.libUnique == "structure_phone").length > 0 && <h4 className="text-white"> {layout.parametres.filter(p => p.libUnique == "structure_phone")[0].valeur}</h4>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="text-white mb-3 border-bottom text-uppercase">Liens Utiles</h4>

                                <ul className="lien-utile">
                                    {
                                        layout.liens_cat_footer.map(f => (
                                            <li key={f.id} className="text-white"><LinkUrl to={f.url}>{f.libelle}</LinkUrl></li>

                                        ))
                                    }

                                </ul>
                            </div>
                            <div className="col-md-6">
                                <h4 className="text-white mb-3 border-bottom text-uppercase">Plan du site</h4>

                                <ul className="lien-utile">
                                    {/* <li className="text-white"><a href="#">Mention légales</a></li> */}
                                    <li className="text-white"><LinkUrl to="/plan">Plan du site</LinkUrl></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="d-flex mb-4">
                            <div className="text-white p-2"><h3 className="text-white"><i className="fas fa-paper-plane"></i></h3></div>
                            <div className="text-white">Écrivez-nous<h4>
                                {layout && layout.parametres.filter(p => p.libUnique == "structure_email").length > 0 && <span className="text-white"> {layout.parametres.filter(p => p.libUnique == "structure_email")[0].valeur}</span>}

                                {/* <a href="" className="text-white">assistances@impots.bj</a> */}
                            </h4></div>
                        </div>
                        <h3 className="text-white fw-light mb-3 "> Inscription à la newsletter <br /><br />
                            <button onClick={() => setShowNewletersModal(true)} type='button' className="btn btn-warning">Je
                                m'inscris
                            </button>
                        </h3>
                        <Newstetter show={showNewsletterModal} toggle={closeModal} />
                    </div>
                </div>

            </div>
            <div className="flag-look-like">
                <div className="col green-block"></div>
                <div className="col yellow-block"></div>
                <div className="col red-block"></div>
            </div>
        </footer>
    );
}

export default Footer; 
