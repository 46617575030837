import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';
import FilAriane from '../layout/ariane';
import HelmetMetaData from "../../constants/header";
import { FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";

function ActualiteItem() {
    const [publication, setPublication] = useState('');
    const [media, SetMedia] = useState('');
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)
    const ariane = publication && {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'ACTUALITÉS', url: '/actualites' },
        tertiary: { title: publication.titre.length > 80 ? `${publication.titre.substring(0, 80)}...` : publication.titre },
    }

    useEffect(() => {
        getResource(`publications?slug=${slug}`).then(res => {
            setPublication(res.data['hydra:member'][0])
            SetMedia(res.data['hydra:member'][0].media)
            setAppState({ ...appState, parent_menu: parent })
        })
    }, []);

    return (

        <div id="main">
            <HelmetMetaData title={publication && publication.titre}
                description={publication && publication.contenuPublication.substring(0, 500)}
                image={`${process.env.REACT_APP_API_BASE_URL}/${media.contentUrl}`}
            ></HelmetMetaData>
            <div className="col-md-12">
                <div className="page-info">
                    {publication && <h4 className="text-center text-white">{publication.titre.length > 80 ? `${publication.titre.substring(0, 80)}...` : publication.titre}</h4>}
                    {publication && <FilAriane ariane={ariane} />}
                </div>
            </div>
            <div className="container mb-3 mt-3">
                <div className="row" style={{ position: 'relative' }}>
                    <div className="col-sm-12 col-md-12 border-bottom-3">
                        <div className="card dgishadow  " style={{ marginBottom: "70px", paddingBottom: "40px" }}>
                            <div>
                                <div className="articleHeader">
                                    <h2 className="text-white text-center mb-3">{publication && publication.titre}</h2>
                                    <p className="articleDate">  <i class="fas fa-calendar ms-3"></i> {publication && publication.publishedAt.slice(0, 10)}</p>
                                </div>
                                {publication && publication.media && <img src={`${process.env.REACT_APP_API_BASE_URL}/${publication.media.contentUrl}`} alt={publication.titre} className="img-fluid articleImg" />}
                                {publication && !publication.media && <img src={'/img/cori.png'} alt="news 1"
                                    className="img-fluid articleImg"
                                />}
                                <div className="d-flex justify-content-between bckColorDark p-3 text-white">
                                    <div>
                                        Partagez sur:
                                        <FacebookShareButton
                                            url={`https://impots.bj/actualites/${publication && publication.slug}`}
                                            quote={","}
                                            hashtag={publication && publication.titre}
                                            description={publication && publication.titre}
                                            className="sharesocial"
                                        >
                                            <FacebookIcon size={24} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            title={publication && publication.titre}
                                            url={`https://impots.bj/actualites/${publication && publication.slug}`}
                                            hashtags={[publication && publication.titre]}
                                            className="sharesocial"
                                        >
                                            <TwitterIcon size={24} round />
                                        </TwitterShareButton>
                                        <WhatsappShareButton
                                            title={publication && publication.titre}
                                            className="sharesocial"
                                            url={`https://impots.bj/actualites/${publication && publication.slug}`}
                                        >
                                            <WhatsappIcon size={24} round />
                                        </WhatsappShareButton>
                                    </div>
                                </div>
                                <div className="page-content" style={{ position: 'relative', padding: '7px 11px 0px 7px', zIndex: 3 }}>
                                    <p className="mt-3 px-4" dangerouslySetInnerHTML={{ __html: `${publication && publication.contenuPublication}` }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ActualiteItem;