
import axios from 'axios';
import { AIB_LOGIN, AIB_PASSWORD } from '../constants';
import Cookies from "js-cookie";

    export const getTokenContribuable = ()=>{
        axios.post('https://aib-cnf-tva.impots.bj/api/auth/signin',{
            password: AIB_PASSWORD,
            usernameOrEmail: AIB_LOGIN
        }).then(res=>{
            Cookies.set('token_aib',res.data.accessToken)
        })
    }

    export const getTokenContribuableInfos = (ifu)=>{
      return  axios.get(`https://aib-cnf-tva.impots.bj/api/aib/status?ifu=${ifu}`, 
        { headers: {"Authorization" : `Bearer ${Cookies.get('token_aib')}`} })
    }