import React, { useEffect, useState } from 'react';
import { getResource } from '../../services/api';

const Contact = () => {
    const [layout, setLayout] = useState(null)
    useEffect(() => {
        getResource(`layout`).then(res => {
            setLayout(res.data)
        })
    }, [])
    return (
        <div id="nouscontacte">
            <div className="container-fluid p-0">
                <div className="row no-gutters align-items-center">

                    <div className="col-sm-12 col-md-6 text-center">
                        <h1 className="text-center text-white mb-3 mt-4"><i className="fas fa-headset headphone"></i></h1>
                        {layout && layout.parametres.filter(p => p.libUnique == "contact_message").length > 0 && <h3 className="text-white mb-3">{layout.parametres.filter(p => p.libUnique == "contact_message")[0].valeur}</h3>}

                        {layout && layout.parametres.filter(p => p.libUnique == "contact_email").length > 0 &&
                            <h4 className="text-white"><i className="fas fa-assistive-listening-systems"
                                style={{ fontSize: 29 }}></i> <a href={`email:${layout.parametres.filter(p => p.libUnique == "contact_email")[0].valeur} `} className='text-light'>
                                    {layout.parametres.filter(p => p.libUnique == "contact_email")[0].valeur}
                                </a></h4>}
                        {layout && layout.parametres.filter(p => p.libUnique == "contact_phone").length > 0 && <a
                            href={`tel:${layout.parametres.filter(p => p.libUnique == "contact_phone")[0].valeur} `}
                            className="btn btn-outline-light mt-3 mb-4 waves-effect waves-light numvertbtn text-center">
                            <span className="numvert"> <i className="fas fa-phone-alt"></i>
                                <b> Nº</b>
                                {/* <small>Vert</small> */}
                            </span><span className="numeroVert"> {layout.parametres.filter(p => p.libUnique == "contact_phone")[0].valeur}
                            </span>
                        </a>}
                        <div className="social-media text-center">
                            {layout && layout.parametres.filter(p => p.libUnique == "facebook_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "facebook_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-facebook" ></i></a>}
                            {layout && layout.parametres.filter(p => p.libUnique == "twitter_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "twitter_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-twitter-square"></i></a>}
                            {layout && layout.parametres.filter(p => p.libUnique == "linkedin_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "linkedin_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-linkedin-in"></i></a>}
                            {layout && layout.parametres.filter(p => p.libUnique == "youtube_url").length > 0 && <a href={`${layout.parametres.filter(p => p.libUnique == "youtube_url")[0].valeur} `} style={{ color: '#fff' }}><i className="fab fa-youtube"></i></a>}
                            <a href="https://www.flickr.com/photos/186545521@N02/" style={{ color: '#fff' }}><i className="fab fa-flickr"></i></a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 p-0 col-md d-none d-md-block">

                        {layout && layout.parametres.filter(p => p.libUnique == "contact_image" && p.valeur != "").length > 0 ? <img src={process.env.REACT_APP_API_BASE_URL + layout.parametres.filter(p => p.libUnique == "contact_image")[0].valeur} alt="" className="d-block mx-auto footer-logo" style={{ width: '100%' }} /> : <img src={'/img/lady.jpg'} alt="" className="d-block mx-auto footer-logo" style={{ width: '100%' }} />}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;