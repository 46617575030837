import React, { Component, PropTypes } from 'react';
import isExternal from 'is-url-external';
import { Link } from 'react-router-dom';



export default class LinkUrl extends Component {
  render() {
    
    return isExternal(this.props.to) ?
      <a
        href={this.props.to}
        target="_blank"
        {...this.props}
       
      />
      :
      <Link {...this.props} />;
  }
}

