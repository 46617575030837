export const urlGenerator = menu => {
    const url = "#"
    const parent = menu.parent ? menu.parent.id ? menu.parent.id.split("/") : menu.parent.split("/") : null

    if (menu.page) {
        if (!menu.parent)
            return `/page/${menu.page.slug}`
        else {
            return `/page/${parent && parent[3]}/${menu.page.slug}`
        }



    }

    if (menu.module) {

        switch (menu.module.template.libUnique) {
            case 'document':
                return `/documentations/${parent && parent[3]}/${menu.module.libUnique}`
                break;

            case 'documentation':
                return `/documentations/${parent && parent[3]}/${menu.module.libUnique}`
                break;

            case 'menu-au-centres':
                return `/documentations_thematiques/${menu.id}/${menu.name}`
                break;

            case 'actus':
                return `/publications/${parent && parent[3]}/${menu.module.libUnique}`
                break;

            default:
                break;
        }
        return `/module/${menu.module.id}`
    }
    if (menu.url) {
        let menu_tmp = menu.url
        if (menu_tmp[0] == "/") {
            menu_tmp = menu_tmp.substring(1, menu_tmp.length)
        }
        let url_menu = menu.url.includes('http') ? menu.url : `/${menu_tmp}`
        return url_menu
    }

    return url
}
