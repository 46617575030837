import React, { useState, useEffect } from 'react';

function CentreItem({centre, fixedCentre}) {

    return (
        <div key={centre.id} className="card mb-3 mt-3 dgishadow" style={{ maxWidth: 540 }}>
        <div className="row g-0 les-centre">
        <div className="col-md-4 p-0 " >
        </div>
            <div className="col-md-8">
                <div className="card-body">
                    <img src="/img/cori.png" height="120" width="120" className="centre-img"/>
                    <h5 className="card-title"><span className="textPrimary cursor" onClick={()=>fixedCentre(centre)}>{centre.libelle}</span>
                        {/* <span className="badge bg-secondary">{centre.departement.libelle}</span> */}
                    </h5>
                    <div className="border-top ">

                        <div>
                            <p className="text-muted xs-font">Responsable</p>
                            <small className=""><b>{centre.responsable}</b> </small>
                        </div>

                    </div>
                    <div className="d-flex justify-content-between ">
                        <div>
                            {centre.serviceContacts && centre.serviceContacts.map(cs => (<span className="xs-font text-uppercase" key={cs.id}>{cs.service.libelle} ( {cs.typeContact.libelle} : {cs.valeur} ) <br /></span>))}
                        </div>

                        {/* <div><span className="xs-font">xxx@impots.bj</span></div>
                <div></div>
                <div></div> */}
                    </div>

                    <p className="card-text"></p>
                </div>
            </div>
        </div>
    </div>

    )
}

export default CentreItem;