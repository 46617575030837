import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ITEM_PER_PAGE } from '../../constants';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'
import moment from 'moment'
import 'moment/locale/fr'
import FilAriane from '../layout/ariane';
moment.locale('fr')

function Echeances() {

    const [echeances, setEcheances] = useState([]);
    const [count, setCount] = useState([]);
    const [parent_menu, setParentMenu] = useState('');
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)
    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])


    useEffect(() => {
        loadData()
        // groupByDate()
    }, [search, currentPage]);

    const loadData = () => {
        getResource(`echeance_fiscales?order[date]=desc&dateButoire[after]=${moment().format('YYYY-MM-DD')}&itemsPerPage=${ITEM_PER_PAGE}&page=${currentPage}${search ? `&libelle=${search}` : ''}`).then(res => {
            // console.log(groupByDate(res.data['hydra:member']));
            setEcheances(groupByDate(res.data['hydra:member']))
            setCount(res.data['hydra:member'].length)
            console.log(count)
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
            setAppState({ ...appState, parent_menus: parent })
        })

        getResource(`menus/${parent}`).then(res => {
            setParentMenu(res.data.name)
        })
    }

    const anneeGenerator = () => {
        let year = []
        for (let index = 2000; index <= parseInt(moment().format('YYYY')); index++) {
            year.push(index)
        }
        return year
    }

    const groupByDate = (data) => {
        // var cars = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }],
        let result = data.reduce(function (r, a) {
            let key = `${a.dateDebut && a.dateDebut} / ${a.dateButoire && a.dateButoire}`
            r[key] = r[key] || [];
            r[key].push(a);
            return r;
        }, Object.create(null));
        return result
    }

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        tertiary: { title: 'Échéances fiscales' },
    }

    return (
        <div>
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white text-uppercase">Échéances ficales</h2>
                    <FilAriane ariane={ariane} />
                </div>
            </div>
            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded mb-3">
                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>
                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div class="container">
                        <div class="event-cards-container">
                            {
                                count > 0 ? Object.keys(echeances).map((key, i) => {
                                    let color = i + 1
                                    if (i > 4) color = parseInt(i % 5) + 1
                                    return (
                                        <div key={i} className={`event event-card one-half alternate-${color}`}>
                                            <div class="d-flex align-content-center flex-wrap">
                                                <div class="event-start">
                                                    <div class="event-start-date">{moment(echeances[key][0].dateDebut).format('DD')}</div>
                                                    <div class="event-start-month"><small>{moment(echeances[key][0].dateDebut).format('MMMM')}</small></div>
                                                </div>
                                                <div class="event-end">
                                                    <div class="event-end-date">-&nbsp;{moment(echeances[key][0].dateButoire).format('DD')}</div>
                                                    <div class="event-end-month"><small>&nbsp;{moment(echeances[key][0].dateButoire).format('MMMM')}</small></div>
                                                </div>
                                            </div>
                                            <div class="event-card-details">
                                                {
                                                    echeances[key].map((ef, index) => {
                                                        return (
                                                            <div class="event-title border-bottom pt-4" title={ef.libelle}>{ef.libelle}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }) :
                                    <span className='h5 text-muted '>Aucune donnée récente disponible.</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Echeances;