import React from 'react';
import _ from 'lodash'
import LinkUrl from '../../lib/link';
import { urlGenerator } from '../../lib/url';

const ServiceUsuel = ({ home }) => {


    return (
        <div id="serviceUtiles">
            { home &&  home.usual_services.length > 0 && <div className="container ps-0 pe-0">

                <div className="row">
                    <div className="col-sm-12">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex  service-usuel__item-head">
                                <h4 className="me-auto text-white">LES SERVICES USUELS</h4>
                                <LinkUrl to="/services_usuels" className="btn ms-auto d-block btn-outline-warning btn-sm">TOUT SAVOIR</LinkUrl >

                            </li>

                            {
                                _.sortBy(home.usual_services, ['position']).map(us => (

                                    <LinkUrl to={urlGenerator(us)} className="aservice" key={us.id}><li  className="list-group-item d-flex justify-content-between align-items-start service-usuel__item">
                                        <div className="me-auto">
                                            <div className="title">{us.libelle}</div>
                                            <small>{us.libelleDetaille}</small>
                                        </div>
                                        <span className="badge  rounded-pill"><i className="fas fa-caret-right"></i></span>
                                    </li>
                                    </LinkUrl>
                                ))
                            }

                        </ul>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default ServiceUsuel;