import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash'
import $ from 'jquery'
import { AppContext } from '.';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import LinkUrl from '../../lib/link';

const Menu = ({ menus, e_services, mecef, layout }) => {

    const [firstLevelMenu, setFirstLevelMenu] = useState(null);
    const [secondLevelMenu, setSecondLevelMenu] = useState(null);
    const [eservice, setEservice] = useState(false);
    const [search, setSearch] = useState("");
    const [menutop, setMenuTop] = useState(menus);
    const [closeModal, setCloseModal] = useState('')


    const { appState, setAppState } = useContext(AppContext)
    let { parent_menus } = appState

    useEffect(() => {

        window.addEventListener('scroll', function () {
            let navbar = document.getElementById('navbar_top')
            setTimeout(function () {
                if (navbar.classList.contains('fixed-top')) {
                    document.getElementById('navbar-brand').classList.remove('show4Mobile');
                } else {
                    document.getElementById('navbar-brand').classList.add('show4Mobile');
                }
            }, 500)
            if (window.scrollY > 90) {
                $('#alertinfo_top').addClass('fixed-top')
                $('#navbar_top').addClass('fixed-top ')

                // const navbar_height = document.querySelector('.navbar').offsetHeight;
                // // document.body.style.paddingTop = navbar_height + 'px';
                $('body').removeClass('fixed-top')

            } else {
                $('#alertinfo_top').removeClass('fixed-top')
                $('#navbar_top').removeClass('fixed-top ')

                //document.body.style.paddingTop = '0';
            }
        });
    }, [])

    const gotoSearch = (event, search) => {
        if (event.key === 'Enter') {
            document.location.pathname = `/recherche/${search}`
        }
    }

    const secondaryMenu = (m1) => {
        setSecondLevelMenu(m1 == secondLevelMenu ? null : m1)
        if (m1.enfants.length == 0) setCloseModal('')
        else setCloseModal('show')
    }

    const primaryMenu = (m) => {
        setFirstLevelMenu(m == firstLevelMenu ? null : m)
        if (m.enfants.length == 0) setCloseModal('')
        else setCloseModal('show')
    }


    return (
        <div className={`bckColor`} id="menu-part">

            <nav id="navbar_top" className={`navbar_top navbar navbar-expand-lg pt-0 pb-0 `}>
                <div className="container-fluid">
                    <button className={`navbar-toggler `} type="button" data-bs-toggle="collapse" onClick={() => setCloseModal('show')} data-bs-target="#main_nav">
                        <i className="fas fa-bars secdText"></i>
                    </button>
                    <a className="navbar-brand show4Mobile animate__flipInY" id="navbar-brand" href="#">
                        <img src={'/img/cori.png'} alt="cori.png" className="" />
                    </a>
                    {layout.parametres.filter(p => p.libUnique == "showServiceOnline" && p.valeur == 1).length > 0 &&
                        <button className="navbar-toggler btn btn-sm btn-outline-warning text-uppercase text-white esmobile" type="button" id="showEservice2" onClick={() => { setEservice(!eservice) }}>
                            {
                                layout.parametres.filter(p => p.libUnique == "titleServiceOnline").length > 0 ? ` ${layout.parametres.filter(p => p.libUnique == "titleServiceOnline")[0].valeur} ` : ' Services en ligne '
                            }
                            {/* <i className="fas fa-ellipsis-v "></i> */}
                        </button>
                    }

                    <div className={`collapse navbar-collapse ${closeModal}`} id="main_nav">
                        <div className="input-group mb-3 pb-3 mt-2 border-bottom show4Mobile">
                            <input type="text" className="form-control" placeholder="Que recherchez-vous ?" value={search} onChange={(event) => setSearch(event.target.value)}
                                aria-label="Recipient's username" aria-describedby="recherche" />
                            <a href={`/recherche/${search}`} className="input-group-text bg-white secdText" id="recherche"><i
                                className="fas fa-search"></i></a>
                        </div>
                        <ul className="navbar-nav mx-auto ">
                            {_.sortBy(menutop, ['position']).filter(menutop => menutop.isPublic == true).map(m => {
                                if (m.isPrincipal !== false)
                                    return (
                                        <li key={m.id} className={`nav-item ${(m.enfants && m.enfants.length > 0) && 'dropdown'} `}><LinkUrl className={`nav-link ${m.id == parent_menus && 'active_'} ${(m.enfants && m.enfants.length > 0) && 'dropdown-toggle '} ${firstLevelMenu && (m == firstLevelMenu) && 'show'} `} data-bs-toggle={(m.enfants && m.enfants.length > 0) && 'dropdown '} onClick={() => primaryMenu(m)} aria-expanded={closeModal == "show" && true} to={urlGenerator(m)}> {m.name} </LinkUrl>
                                            {/* First Level */}
                                            {
                                                (m.enfants && m.enfants.length > 0) &&
                                                <ul className={`dropdown-menu ${firstLevelMenu && (m == firstLevelMenu) && 'show'}`}>
                                                    {_.sortBy(m.enfants, ['position']).filter(menu => menu.isPublic == true).map(m1 => {
                                                        if (m1.isPrincipal === true)
                                                            return (
                                                                <li key={m1.id} className={`nav-item ${(m1.enfants && m1.enfants.length > 0) && 'dropdown text-uppercase'}`}><LinkUrl className={`dropdown-item text-uppercase  ${(m1.enfants && m1.enfants.length > 0) && ' '} ${secondLevelMenu && (m1 == secondLevelMenu) && 'show'} `} data-bs-toggle={(m1.enfants && m1.enfants.length > 0) && 'dropdown '} onClick={() => secondaryMenu(m1)} to={urlGenerator(m1)}>  {m1.name} {m1.enfants.filter(ms => ms.isPrincipal).length > 0 && <i className="fas fa-caret-right"></i>}</LinkUrl>
                                                                    {/* Second Level */}
                                                                    {
                                                                        (m1.enfants && m1.enfants.length > 0) &&
                                                                        <ul className={`submenu ${secondLevelMenu && (m1 == secondLevelMenu) && 'show'}`}>
                                                                            {_.sortBy(m1.enfants, ['position']).map(m2 => {
                                                                                if (m2.isPrincipal === true)
                                                                                    return (
                                                                                        <li key={m2.id} className={`nav-item `}><LinkUrl className={`dropdown-item `} onClick={() => setCloseModal('')} to={urlGenerator(m2)}> {m2.name} </LinkUrl></li>)
                                                                            })}
                                                                        </ul>
                                                                    }
                                                                </li>)
                                                    })}
                                                </ul>
                                            }
                                        </li>
                                    )
                            }
                            )}

                            {layout.parametres.filter(p => p.libUnique == "showServiceOnline" && p.valeur == 1).length > 0 && <li className="nav-item hide4mobile" id="showEservice">
                                <a
                                    className="nav-link showSearch  waves-light ps-3"
                                    href="#" onClick={() => { setEservice(!eservice) }}> <i className="fas fa-th"></i>
                                    {
                                        layout.parametres.filter(p => p.libUnique == "titleServiceOnline").length > 0 ? ` ${layout.parametres.filter(p => p.libUnique == "titleServiceOnline")[0].valeur}` : ' Services en ligne'
                                    }
                                    &nbsp;</a></li>}

                            <li className="nav-item search-dropdown hide4mobile">
                                <div className="search-box">
                                    <input type="text" placeholder="Que recherchez-vous ?" className="search-input text-white" value={search} onKeyPress={(event) => gotoSearch(event, search)} onChange={(event) => setSearch(event.target.value)} />
                                    <LinkUrl to={`/recherche/${search}`} className="search-btn" >
                                        <i className="fas fa-search"></i>
                                    </LinkUrl>
                                </div>
                                <a className="nav-link dropbtn showSearch  waves-light">
                                    <i className="fas fa-search"></i>
                                </a>
                                <div id="myDropdown" className="dropdown-content">

                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control text-white" value={search} aria-label="Recherche"
                                            aria-describedby="basic-addon2" onChange={(event) => setSearch(event.target.value)} />
                                        <span className="input-group-text" id="basic-addon2"><i
                                            className="fas fa-search"></i></span>
                                    </div>
                                    <div className="searchResultArea">
                                        <h6 className="text-center text-white">Que recherchez-vous ?</h6>
                                    </div>
                                </div>
                            </li>

                            <li className="border-bottom show4Mobile "></li>
                            {_.sortBy(layout.liens_cat_header, ['position']).map((el, index) => (
                                <li key={el.id} className="nav-item paddingzero show4Mobile " onClick={() => setCloseModal('')}>
                                    <LinkUrl className={`nav-link paddingzero show4Mobile  ${(index + 1 === layout.liens_cat_header.length) && 'nav-link-noborder'}`} to={el.url && el.url.includes('http') ? el.url : `/${el.url}`}>{el.libelle}</LinkUrl>
                                </li>
                            ))}



                        </ul>

                    </div>

                </div>
                <div className="topshareBtn hide4mobile">
                    <div className="topshareBtn-content">

                        {layout.parametres.filter(p => p.libUnique == "facebook_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "facebook_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-facebook"></i></a>}
                        {layout.parametres.filter(p => p.libUnique == "twitter_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "twitter_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-twitter-square"></i></a>}
                        {layout.parametres.filter(p => p.libUnique == "linkedin_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "linkedin_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-linkedin-in"></i></a>}
                        {layout.parametres.filter(p => p.libUnique == "youtube_url").length > 0 && <a className="z-depth-2" href={`${layout.parametres.filter(p => p.libUnique == "youtube_url")[0].valeur} `} style={{ color: '#fff' }}><i className="topsocial-icons fab fa-youtube"></i></a>}
                        <a className="z-depth-2" href="https://www.flickr.com/photos/186545521@N02/" style={{ color: '#fff' }}><i className="topsocial-icons fab fa-flickr"></i></a>
                    </div>
                    <span className="z-depth-2"><i className="fas fa-thumbs-up"></i></span>

                </div>
            </nav>
            <div id="eserviceSidenav" className="eservice-sidenav" style={{ width: eservice ? 400 : 0, padding: eservice ? 5 : 0 }}>
                <a href="#" onClick={() => { setEservice(!eservice) }} className=" btn-floating closebtn  waves-light"
                    id="closeEserviceNav">&times;</a>
                <div className="card mb-2">
                    <h6 className="card-title textPrimary border-bottom p-2"> Services en ligne</h6>
                    <div className="card-body">
                        <div className="list-group eserviceList">
                            {
                                e_services && _.sortBy(e_services, ['position']).map(es => (
                                    <LinkUrl key={es.id} to={urlGenerator(es)} className="list-group-item list-group-item-action" aria-current="true">
                                        {es.libelle}
                                    </LinkUrl>
                                ))
                            }

                        </div>
                        {/* <p className="showmoreEservice">Voir plus <i className="fas fa-caret-down"></i></p> */}
                    </div>
                </div>
                <div className="card mb-2">
                    <h6 className="card-title textPrimary border-bottom p-2"> MECEF</h6>
                    <div className="card-body">
                        <div className="list-group eserviceList">
                            {
                                mecef && _.sortBy(mecef, ['position']).map(es => (
                                    <LinkUrl key={es.id} to={urlGenerator(es)} className="list-group-item list-group-item-action" aria-current="true">
                                        {es.libelle}
                                    </LinkUrl>
                                ))
                            }

                        </div>
                        {/* <p className="showmoreEservice">Voir plus <i className="fas fa-caret-down"></i></p> */}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Menu;
