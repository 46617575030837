import React from 'react';
import moment from "moment"
import 'moment/locale/fr'
import LinkUrl from '../../lib/link';
moment.locale('fr')

const EcheanceFiscale = ({ home }) => {
    return (
        <div id="echeance">
            <h2 className="fw-bold text-center titleHolder">Échéances Fiscales
            </h2>
            <div className="container pt-2 mt-5 mb-4">
                <div className="text-center">
                    <LinkUrl className="btn btn-sm btn-outline-warning text-black text-uppercase" to="/echeance_fiscales">Voir le
                        calendrier <i className="fas fa-calendar-alt"></i></LinkUrl>

                    <LinkUrl className="btn btn-sm btn-outline-success text-black text-uppercase space-left " to="/centres">Centres proches de moi <i className="fas fa-map-marker"></i></LinkUrl>
                </div>
                <div className="content">
                    <div className="gw">
                        <div className="row mt-3">
                            <div className="col-sm-12 g one-whole tablet-two-thirds">
                                <div className="event-tiles-container">
                                    {
                                        home && home.echeance_fiscale.slice(0,4).map((ef, index) => {
                                            let color = index+1
                                            if(index>4) color=parseInt(index%5)+1
                                            return(
                                                <div key={ef.id} className={`event event-tile one-half alternate-${color}`}>
                                                <div className="event-start">
                                                    <div className="event-start-date">{moment(ef.dateDebut).format('DD')}</div>
                                                    <div className="event-start-month"><small>{moment(ef.dateDebut).format('MMMM')}</small></div>

                                                </div>

                                                <div className="event-end">
                                                    <div className="event-end-date">-&nbsp;{moment(ef.dateButoire).format('DD')}</div>
                                                    <div className="event-end-month"><small>{moment(ef.dateButoire).format('MMMM')}</small></div>

                                                </div>

                                                <div className="event-title">{ef.libelle}</div>

                                                {/* <div className="event-time">
                                                    <small>
                                                        <i className="fa fa-clock-o"></i>&nbsp;&nbsp;
                                                        9:00 - 18:00
                                                    </small>
                                                </div> */}


                                                {/* <div className="event-location">
                                        <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#lesCentre">
                                            <small>
                                                <i className="fa fa-map-marker"></i>&nbsp;&nbsp;
                                                Votre centre
                                            </small>
                                        </a>
                                    </div> */}

                                            </div>
                                            )
                                        })
                                    }


                                </div>
                            </div>


                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default EcheanceFiscale;