import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import _ from 'lodash'
import { AppContext } from '../layout'

import { urlGenerator } from '../../lib/url';
import FilAriane from '../layout/ariane';
import LinkUrl from '../../lib/link';

function MenuCenter() {

    const [currentPage, setCurrentPage] = useState(1)
    let { slug, parent } = useParams();
    const [parent_menu, setParentMenu] = useState(null);
    const { appState, setAppState } = useContext(AppContext)


    useEffect(() => {
        loadData()
    }, [slug]);


    const loadData = () => {

        getResource(`menus/${parent}`).then(res => {
            setParentMenu(res.data)
            setAppState({ ...appState, parent_menus:  res.data.parent && res.data.parent.id })

        })
    }

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: parent_menu && parent_menu.parent.name, url:'' },
        tertiary: { title: slug },
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{slug}</h2>
                    {parent_menu && <FilAriane ariane={ariane} />}

                </div>


            </div>

            <div className="container mb-3 mt-3">

                <div className="row mt-3">
                    <div className="card p-2 col-sm-12 ">
                        <div className="accordion row" id="centeraccordeon">
                            {parent_menu && _.sortBy(parent_menu.enfants, ['name']).reverse().map((menu,index) => (
                                <div  key={menu.id} className="accordion-item  mt-3 col-sm-12  ">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed text-uppercase dgishadow" type="button" data-bs-toggle="collapse"
                                            data-bs-target={`#menutop${index}`} aria-expanded="false" aria-controls={`menutop${index}`}>
                                            {menu.name}
                                        </button>
                                    </h2>
                                    <div id={`menutop${index}`} className="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#centeraccordeon">
                                        <div className="accordion-body border-bottom">
                                            <div className="list-group">
                                                {
                                                    _.sortBy(menu.enfants, ['name']).reverse().map(e=>(
                                                        <LinkUrl key={`menuchildren${e.id}`} to={urlGenerator(e)} className="list-group-item list-group-item-action pt-3 pb-3" aria-current="true">{e.name}</LinkUrl>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}



                        </div>
                    </div>




                </div>
            </div>


        </div>
    )
}

export default MenuCenter;