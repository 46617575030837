import axios from 'axios';
import Cookies from "js-cookie";

/** Get Access Token For App */
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL + '/api/';
// const access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MjM3MDE2MzUsImV4cCI6MTYyMzcwNTIzNSwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6Imp1ZGljYWVsYmRpbW9uQGdtYWlsLmNvbSJ9.LS8HhiRX4dPRql-MITj4Oj4-64cxKsHuNYlfL1KN1DafiUm1udWpBC8Z4yS93JqTfVw26W1q0wRxueR35GYAFjXfpzn1g_KFWJ5wszLn7bVoo1qU8I-dpbpMzOlLZ3_E2ccw6NWdTfv1a-M9hqoMF9yp66VWakaDwyF169HoLJq9I40U150bw4DRGpgll6dv8kI-paxZivp_lsr-u1RwiMDLEu67kAx084knEtNN-kvLIA7weX09HKes_DbU5f5GaFIIk3YL-bmoXqrTwAtnxCWCK5XFfJJHhNwFtFTuzPYg3VmWTa5RT47SmjUu9AY0e8osWLVwLm0p4PJB5jH5-A';
axios.interceptors.request.use(async function (config) {
    if (typeof (access_token) === 'string') {
      //  config.headers.Authorization = `Bearer ${access_token}`;
      //  config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMWM3Yzk5YWJlZmIwYTA2NDRkMjc3NWE0NmU2Mjk1NmE0Y2M1OWM5YThjNDdlMWJiZTc2NTAzODY5MGQ2OGRjMDhiNjMzMmM3OGFlM2UyNDIiLCJpYXQiOiIxNjE2MTA5MDQ2LjY3MTUzNiIsIm5iZiI6IjE2MTYxMDkwNDYuNjcxNTQwIiwiZXhwIjoiMTYzMjAwNjY0Ni41NDkwMzciLCJzdWIiOiJiMGY5YWRmOC0wOTc3LTRkNGEtYjNiNy0xNTllZjlkNzRlNDQiLCJzY29wZXMiOltdfQ.LoYDem__1ecEWvie95tgPKPa0rBG5dWhbq_ZjJtK7emi6D5ZbfdtGEmOzfkdb4_ljI9HPKKC1tdp7-gA234xC_uZPfbt60oFp8S6d2L0o6-1fdUNJ7fsHMRgt-MCubA9lcrdTxDNMkD32aVfWeLhesJWuT3WLeclnFMemDlNFiPmUHXvDiw6VQcgfLfQAeVwuKA7lzBr2L-bID8wYpDg6alIKAfX7ip4zS6qb3TK8N35uD8GQrBcHbSbaU7cnv1q-IA9Y0syRZMaXEzKlJ6vfcFzHWEYBBDgrHdHY8begkWW39H-_qTEUA8qDV3Rpa-XlI15XeXA0sk4JmZNur96gx5HT3PfQDmywsvGHBHjECrZGiGArmTPmIb_z7g3FzXwynMul7XvVEMKIIS5yJIAuPCfTiQmKqkDkDHaz0Nvf7WIskIu_oz-9t8deVgyZNE_g0WXXRv0UauRlhiKLkOorWetD2dUKucbf-quqvKzN7nsBXsHDKXZcwsQc2TuphCQBdM920ZlPOOMwR48sDMBzapuCcfgTpJL-QDZMCHOnvo61aPcbf7gMB8m1095KC2GyOATmKGiFNfzbie7iV6yrAd7sIdcyT3s6rZ6RuKxdbPEw6-MUsjVgws7eTkshU-zqdnbVW5o2QnSXRSmr7zW_bJav8UfdGUIYL9gMamWcKs`;
        // config.headers['cache-control'] = `no-cache`;
    }
    return config;
}, function (err) {
    console.log('Erreur', err);
   // return Promise.reject(err);
});

/**
 *
 * @param {string} resource_url
 * @param {Array} filters
 * @param {number} limit
 */
export const getResource = (resource_url) => {
  return axios.get(resource_url, {headers: {'Content-Type': 'application/ld+json', 'Accept': 'application/ld+json'}})
}


/**
 *
 * @param {string} resource_url - Url for API
 * @param {object} data - Data
 * @param {object} headers
 */
export const postResource = (resource_url, data, headers) => {

  return axios.post(resource_url, data, headers)
}

/**
 *
 * @param {string} resource_url
 * @param {number} id
 * @param {object} data
 * @param {object} headers
 */
export const putResource = (resource_url, id, data, headers) => {
  return axios.put(resource_url + '/' + id, data, headers)
}

export const putResourceByUrl = (resource_url, data, headers) => {
  return axios.put(resource_url, data, headers)
}

/**
 *
 * @param {string} resource_url
 * @param {number} id
 */
export const removeResource = (resource_url, id) => {
  return axios.delete(resource_url + '/' + id)
}
