export const sliderConfig = {
    dots: false,
    infinite: true,
    arrows:true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <button className='slick-prev'><i className='fas fa-long-arrow-alt-left'></i></button>,
    nextArrow: <button className='slick-next'><i className='fas fa-long-arrow-alt-right'></i></button>,
    focusOnSelect: true,
    draggable: true,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                dots: false

            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false

            }
        }
    ]
}

export const ITEM_PER_PAGE = 6

export const GOOGLE_MAP_API_KEY = 'AIzaSyCo-Twdg-fAUZm3f7aBeJQDkCU2bQfLiVQ'

export const AIB_LOGIN = "aib"
export const AIB_PASSWORD = "@ibCnfTV@_2020"
