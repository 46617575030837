import React, { useEffect } from 'react';
import Marquee from './marquee';
import Menu from './menu';
import $ from 'jquery'
import _ from 'lodash';
import LinkUrl from '../../lib/link';
import { urlGenerator } from '../../lib/url';
const Header = ({ layout, menus }) => {

    useEffect(() => {

        if (layout.parametres.filter(p => p.libUnique == "alert" && p.valeur == 1)) {
            $('.traducoes').removeClass("traducoeschange")

            $('.navbar_top').addClass('marginTop-navBar')
        }
        if (layout.parametres.filter(p => p.libUnique == "alert" && p.valeur == 0)) {
            $('.traducoes').addClass("traducoeschange")
            $('.navbar_top').removeClass('marginTop-navBar')

        }
        $('.traducoes').removeClass("traducoeschange")

    }, [])

    return (
        <>
            <div className="d-flex alert-zone" id="alertinfo_top" >

                {layout.parametres.filter(p => p.libUnique == "alert" && p.valeur == 1).length > 0 && <Marquee datas={layout.flash_infos} />}
            </div>

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid topmenu">
                    {layout.parametres.filter(p => p.libUnique == "logo" && p.valeur != "").length > 0 ? <LinkUrl className="" to="/"><img src={layout.parametres.filter(p => p.libUnique == "logo")[0].valeur} alt="" className="site-logo" /></LinkUrl> : <LinkUrl className="" to="/"><img src={'/img/logo2.png'} alt="" className="site-logo" /></LinkUrl>}

                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav mx-auto secondaryMenu hide4mobile">
                            {_.sortBy(layout.liens_cat_header, ['position']).filter(menu => menu.isPublic == true).map((el, index) => (
                                <li key={el.id} className="nav-item">
                                    {/* <LinkUrl className={`nav-link ${(index +1  === layout.liens_cat_header.length)&&'nav-link-noborder'}`} to={(el.url && el.url!=="") && el.url.includes('http')?el.url:`/${el.url}`}>{el.libelle}</LinkUrl> */}
                                    <LinkUrl className={`nav-link ${(index + 1 === layout.liens_cat_header.length) && 'nav-link-noborder'}`} to={`${urlGenerator(el)}`}>{el.libelle}</LinkUrl>
                                </li>
                            ))}


                        </ul>
                        <ul className="navbar-nav ms-auto secondaryMenu">
                            {/* <li className="nav-item"><a className="nav-link mt-2" href="#"><i className="fas fa-language"></i>
                        English</a>
                            </li> */}
                            {layout.parametres.filter(p => p.libUnique == "espace_contribuable" && p.valeur == 1).length > 0 && <li className="nav-item"><LinkUrl to="" className="mon-espace"><i className="fas fa-user-secret"></i>&nbsp;
                                Espace contribuable</LinkUrl></li>}
                        </ul>

                    </div>
                </div>
            </nav>
            <Menu menus={menus} e_services={layout.e_services} mecef={layout.mecef} layout={layout} />

        </>
    );
}

export default Header;