import React from 'react';
import Marquee from "react-fast-marquee"
import LinkUrl from '../../lib/link';

const MarqueeNews = ({datas}) => {
    return (
        <div className="d-flex alert-zone" id="alertinfo_top" >
            <div className="p-1 pe-2 bd-highlight bg-dark text-white info-flash">
                <span className="hide4mobile">Alerte info </span><i
                className="fas fa-bullhorn fa-flip-horizontal secdText"></i>
            </div>

            <Marquee  pauseOnHover speed={60} gradient={false}>
                {datas.map(marquee=>(
                <LinkUrl key={marquee.id} to={`/actualites/${marquee.slug}`} className="nodecorator">{marquee.titre}</LinkUrl>            
                ))}
            </Marquee>
        </div>
    );
}

export default MarqueeNews;
