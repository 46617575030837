import axios from 'axios';

export default axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params:{
      part: 'snippet,id',
      maxResults: 8,
      order: 'date',
      type: 'video',
      channelId: 'UCMPCgDsaP5Xd3BaTgrFDWmA',
      key: 'AIzaSyDbITO3qNfFGmsuVKG09CcgICg-NiCSNlg'
  }
})

// key: 'AIzaSyDbITO3qNfFGmsuVKG09CcgICg-NiCSNlg'
