import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { getResource } from '../../services/api';
import LinkUrl from '../../lib/link';

const Regime = ({ home }) => {
    const [layout, setLayout] = useState(null)
    useEffect(() => {
        getResource(`layout`).then(res => {
            setLayout(res.data)
        })
    }, [])
    return ( 
        <div className="sction bg-gray-light" id="regimes">
        <div className="container-fluid">
            <div className="row">
                <h2 className="fw-bold text-center titleHolder">Vous êtes ...</h2>
                {home && home.regimes.length > 0 && 

                <div className="regime-container  mt-5">
                    {
                        _.sortBy(home.regimes, ['position']).map(regime=>(
                            <div className="box" key={regime.id}>
                            <div className="icon"><i className={`fas ${regime.icon}`}></i></div>
                            <div className="content">
                                <h3>{regime.libelle}</h3>
                                <LinkUrl to={regime.url}>Lire plus</LinkUrl>
                            </div>
                        </div>                           
                        ))
                    }

                </div>
 }

            </div>
           
                    { layout && layout.parametres.filter(p=>p.libUnique=="showRechercheContribuable" && p.valeur==1).length >0 && <li className="nav-item"> <a href="/recherche_ifu" className="btn btn-warning text-dark mt-5 mb-4 inversebtn">Rechercher un
                    contribuable</a></li>}
        </div>
    </div>
     );
}
 
export default Regime;