import React, { useEffect, useState } from 'react'
import youtube from "../../services/youtube";
import moment from "moment"
import 'moment/locale/fr'
import LinkUrl from '../../lib/link';
import { getResource } from '../../services/api';
moment.locale('fr')



const RegimeActualites = ({ slug }) => {
    const [actualites, setActualites] = useState([]);

    useEffect(()=>{
        getResource(`publications?tags.nom=${slug}&order[createdAt]=desc`).then(res=>{
            setActualites(res.data['hydra:member'])
        })
    },[])
 
    return (

            <div className="container ">
                        <div className="row">
                            <div className="col-md-12">
                            {actualites.length >0 && <h4 class="mb-2 mt-4">L’ACTUALITE EN BREF</h4>}

                                
                            </div>

                            {
                                actualites.slice(0, 3).map(act => (
                                    <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                        <div className="single-actu-item">
                                            <div className="actu-image">
                                                <LinkUrl to={`/actualites/${act.slug}`}>
                                                    {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt="news 1"
                                                        className="img-fluid" />}
                                                    {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                        className="img-fluid" />}
                                                </LinkUrl>
                                                <div className="shareBtn">
                                                    <div className="shareBtn-content">
                                                        <a href="" className="z-depth-2 "><i
                                                            className="social-icons fab fa-facebook"></i></a>
                                                        <a href="" className="z-depth-2 "><i
                                                            className="social-icons fab fa-twitter"></i></a>
                                                        <a href="" className="z-depth-2 "><i
                                                            className="social-icons fab fa-youtube"></i></a>
                                                    </div>
                                                    <span className="z-depth-2"><i className="fas fa-share-alt"></i></span>

                                                </div>
                                                <div className="actu-meta">
                                                    <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="actu-text">
                                                <LinkUrl  to={`/actualites/${act.slug}`}>
                                                    <h6 className="text-uppercase" >{act.titre.slice(0, 40)}...</h6>
                                                </LinkUrl>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }

                        </div>
                        {/* {
                        actualites && <button className="actu-btn  mx-auto d-block mt-4 mb-3 orange waves-effect waves-orange waves-ripple">
                            <LinkUrl to="/actualites" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</LinkUrl> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                        } */}
                   
        </div>

    );
}

export default RegimeActualites;