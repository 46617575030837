import React from 'react';
import _ from 'lodash'
import LinkUrl from '../../lib/link';
import { urlGenerator } from '../../lib/url';
const ServiceUsager = ({ home }) => {
    return (
        <div id="serviceUsasger">
            {home && home.usage_services.length > 0 && <div>
                <h2 className="fw-bold text-center titleHolder">Services aux usagers</h2>
                <div className="row space-rows" id="animated-cards">
                    {
                        _.sortBy(home.usage_services, ['position']).filter(menu => menu.isPublic == true).map(us => (
                            <div className="col-sm-12 col-md-4 mt-5" key={us.id}>
                                <LinkUrl to={urlGenerator(us)} >
                                    <div className="card cards-shadown cards-hover">
                                        <div className="card-header">
                                            <span className="space">
                                                <i className={`fas ${us.icon} -shield service-icon mt-1`}></i>
                                            </span>
                                            <div className="cardheader-text ">
                                                <h4 className="heading-card text-uppercase">{us.libelle}
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </LinkUrl>
                            </div>
                        ))
                    }
                </div>
            </div>
            }
        </div>
    );
}

export default ServiceUsager;