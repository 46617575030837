import React, { useState, useEffect } from 'react';

function Loading() {


    return (
        <div className="loading-scr">
        <div className="loading-animation">
            <img src={'/img/cori.png'} alt="Linkedin logo" className="loading-logo"/>
            <div className="load-bar"></div>
        </div>
    </div>
    )
}

export default Loading;