import React, { useEffect, useState } from 'react'
import youtube from "../../services/youtube";
import moment from "moment"
import 'moment/locale/fr'
import LinkUrl from '../../lib/link';
import { FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { Helmet } from "react-helmet";
import HelmetMetaData from "../../constants/header";
moment.locale('fr')



const Actualites = ({ home }) => {
    const [nextToken, setNextToken] = useState('')
    const [prevToken, setPrevToken] = useState('')
    const [next, setNext] = useState(false)
    const [prev, setPrev] = useState(false)
    const [moduleVideos, setModuleVideos] = useState([])
    useEffect(() => {
        youtube.get('/search', {
            params: {
                pageToken: nextToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);

            setNextToken(res.data.nextPageToken)
        })
    }, [next])

    useEffect(() => {
        youtube.get('/search', {
            params: {
                pageToken: prevToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);
            setPrevToken(res.data.prevPageToken)
        })
    }, [prev])

    return (
        <div id="lesactualite">
            <h2 className="fw-bold text-center titleHolder">Retrouvez toute l’actualité</h2>
            <div className="container ps-0 pe-0">
                <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#actualites"
                            type="button"
                            role="tab" aria-controls="home" aria-selected="true">ACTUALITÉS
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#communiques" type="button"
                            role="tab"
                            aria-controls="profile" aria-selected="false">COMMUNIQUÉS
                        </button>
                    </li>

                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-uppercase" data-bs-toggle="tab" data-bs-target="#notedeservice"
                            type="button"
                            role="tab"
                            aria-controls="contact" aria-selected="false">Les Réformes
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-uppercase" data-bs-toggle="tab" data-bs-target="#revuepresse"
                            type="button"
                            role="tab"
                            aria-controls="contact" aria-selected="false">vidéothèque
                        </button>
                    </li>
                </ul>
                <div className="tab-content " id="myTabContent">
                    <div className="tab-pane fade show active" id="actualites" role="tabpanel">
                        <div className="row">
                            {
                                home && home.actus.slice(0, 3).map(act => (
                                    <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                        <div className="single-actu-item">
                                            <div className="actu-image">
                                                <LinkUrl to={`/actualites/${act.slug}`}>
                                                    {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt="news 1"
                                                        className="img-fluid" />}
                                                    {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                        className="img-fluid" />}
                                                </LinkUrl>
                                                <div className="shareBtn">
                                                    <div className="shareBtn-content">
                                                        <FacebookShareButton
                                                            url={`https://impots.bj/actualites/${act && act.slug}`}
                                                            quote={","}
                                                            hashtag={act && act.titre}
                                                            description={act && act.titre}
                                                            className=" sharesocial_ social-icons"
                                                        >
                                                            <FacebookIcon size={32} round />
                                                        </FacebookShareButton>
                                                        <TwitterShareButton
                                                            title={act && act.titre}
                                                            url={`https://impots.bj/actualites/${act && act.slug}`}
                                                            hashtags={[act && act.titre]}
                                                            className=" sharesocial_ social-icons"
                                                        >
                                                            <TwitterIcon size={32} round />
                                                        </TwitterShareButton>
                                                        <WhatsappShareButton
                                                            title={act && act.titre}
                                                            className="  sharesocial_ social-icons"
                                                            url={`https://impots.bj/actualites/${act && act.slug}`}
                                                            image="https://api.impots.bj//media/61bcc702c7774_IMG_3705-1.jpg"
                                                        >
                                                            <WhatsappIcon size={32} round />
                                                        </WhatsappShareButton>
                                                    </div>
                                                    <span className="z-depth-2"><i className="fas fa-share-alt"></i></span>
                                                </div>
                                                <div className="actu-meta">
                                                    <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="actu-text">
                                                <LinkUrl to={`/actualites/${act.slug}`}>
                                                    <h3 className="text-uppercase">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre}</h3>
                                                </LinkUrl>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 orange waves-effect waves-orange waves-ripple">
                            <LinkUrl to="/actualites" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</LinkUrl> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>

                    <div className="tab-pane fade " id="communiques" role="tabpanel">
                        <div className="row">
                            {
                                home && home.communiques.slice(0, 3).map(act => (
                                    <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                        <div className="single-actu-item">
                                            <div className="actu-image">
                                                <LinkUrl to={`/communiques/${act.slug}`}>
                                                    {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt="news 1"
                                                        className="img-fluid" />}
                                                    {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                        className="img-fluid" />}
                                                </LinkUrl>
                                                <div className="shareBtn">
                                                    <div className="shareBtn-content">
                                                        <FacebookShareButton
                                                            url={`https://impots.bj/actualites/${act && act.slug}`}
                                                            quote={","}
                                                            hashtag={act && act.titre}
                                                            description={act && act.titre}
                                                            className=" sharesocial_ social-icons"
                                                        >
                                                            <FacebookIcon size={32} round />
                                                        </FacebookShareButton>
                                                        <TwitterShareButton
                                                            title={act && act.titre}
                                                            url={`https://impots.bj/actualites/${act && act.slug}`}
                                                            hashtags={[act && act.titre]}
                                                            className=" sharesocial_ social-icons"
                                                        >
                                                            <TwitterIcon size={32} round />
                                                        </TwitterShareButton>
                                                        <WhatsappShareButton
                                                            title={act && act.titre}
                                                            className="  sharesocial_ social-icons"
                                                            url={`https://impots.bj/actualites/${act && act.slug}`}
                                                        >
                                                            <WhatsappIcon size={32} round />
                                                        </WhatsappShareButton>
                                                    </div>
                                                    <span className="z-depth-2"><i className="fas fa-share-alt"></i></span>
                                                </div>
                                                <div className="actu-meta">
                                                    <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="actu-text">
                                                <LinkUrl to={`/communiques/${act.slug}`}>
                                                    <h3 className="text-uppercase">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre}</h3>
                                                </LinkUrl>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 orange waves-effect waves-orange waves-ripple">
                            <LinkUrl to="/communiques" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</LinkUrl> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>

                    <div className="tab-pane fade " id="notedeservice" role="tabpanel">
                        {home && home.reformes_objets.length > 0 ? home.reformes_objets.slice(0, 3).map(act => (
                            <LinkUrl key={act.id} to={`/reformes/${act.slug}`} className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="commiqueTitle">{act.libelle}</h5>
                                    <p className="comminqueDate"><i className="fas fa-calendar-day"></i> <br />{moment(act.createdAt).format('DD')} <br /> {moment(act.createdAt).format('MMMM')} <br /> <span
                                        className="annee">{moment(act.createdAt).format('YYYY')}</span></p>

                                </div>
                            </LinkUrl>

                        )) : <h6 className='text-center m-3 text-muted'>Aucune donnée dispinible</h6>
                    }
                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 orange waves-effect waves-orange waves-ripple">
                            <LinkUrl to="/reformes" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</LinkUrl> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>

                    <div className="tab-pane fade" id="revuepresse" role="tabpanel">
                        <div class="container m-2">
                            <div class="row">
                                {
                                    moduleVideos.slice(0, 3).map(module => (
                                        <div class="col-lg-4 col-sm-12" key={module.id.videoId}>
                                            <div class="single-teachers mt-30 text-center">
                                                <div class="image">
                                                    <iframe className="styles"
                                                        src={`https://www.youtube.com/embed/${module.id.videoId}`}>
                                                    </iframe>
                                                </div>
                                                <div class="cont">
                                                    <a className="cursor text-light" href={`https://www.youtube.com/watch?v=${module.id.videoId}`}>{module.snippet.title}</a>
                                                </div>
                                            </div>
                                        </div>))
                                }
                            </div>
                        </div>
                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 orange waves-effect waves-orange waves-ripple" >
                            <a style={{ marginTop: -3, color: '#FFF' }} href='https://www.youtube.com/channel/UCMPCgDsaP5Xd3BaTgrFDWmA'>VOIR PLUS</a> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                </div>
            </div>
        </div>

    );


}



export default Actualites;