import React, { useState, useEffect, useRef } from 'react';
import { getResource } from '../../services/api';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import Geocode from "react-geocode";
import CentreItem from './item';
import { useParams } from 'react-router-dom';
import LinkUrl from '../../lib/link';

Geocode.setApiKey(GOOGLE_MAP_API_KEY);

Geocode.setLanguage("fr");
Geocode.setRegion("bj");


function Centre({ google }) {
    const [centres, setCentres] = useState([]);
    const [departements, setDepartements] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const [userAddress, setUserAddress] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [activeMarkerData, setActiveMarkerData] = useState(null);
    let { localite } = useParams();
    const [localisation, setLocalisation] = useState(localite);
    const timer = ms => new Promise(res => setTimeout(res, ms))
    const [initialCenter, setInitialCenter] = useState({lat:6.37,lng:2.40});

    const mapRef = useRef(null);


    useEffect(() => {
         getCurrentLocation()
        loadDepartements()
        load()
    }, []);


    useEffect(() => {
        if (userAddress ) {
            getResource(`localites?libUnique=${userAddress}`).then(res=>{
                const address = res.data['hydra:member']
                if(address.parent){
                    loadCentres(address.parent.libUnique)

                }else {
                    loadCentres(userAddress)
                }
            })
        }

    }, [userAddress]);

    useEffect(() => {
        loadCentres()

    }, [localite]);

    const loadCentres = (address=null) => {
        getResource(`centres?pagination=false${localite ?`&localite.libUnique=${localite}`:address?`&localite.libUnique=${address}`:''}`).then(async (res) => {
            setCentres(res.data['hydra:member'])
            fixedZones(res.data['hydra:member'])
        })
    }


    const loadDepartements = () => {
        getResource(`localites?nivLocalite=1&pagination=false`).then(res => {
            setDepartements(res.data['hydra:member'])
        })
    }

    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setUserPosition(position)
            getCurrentAdress(position)
        });
    }

    const getCurrentAdress = (position) => {

        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                setUserAddress(address)
            },
            (error) => {
                console.error(error);
            }
        );
    }


    function onMarkerClick(props, marker, e, data) {
        setActiveMarker(marker);
        setActiveMarkerData(data);
        setShowingInfoWindow(true);
    }


    const onInfoWindowClose = () => { }

    async function load () { 
        for (var i = 0; i < 3; i++) {
          await timer(2000); 
          setShowMap(true)

        }
      }

 

    const fixedCentre = (centre)=>{
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(new google.maps.LatLng(centre.latitude, centre.longitude));
        if(mapRef.current){
            mapRef.current.map.fitBounds(bounds);
            mapRef.current.map.setZoom(15);
        }

    }

    const fixedZones = (centres)=>{
        const bounds = new google.maps.LatLngBounds();
        centres.map(centre=>{
            bounds.extend(new google.maps.LatLng(centre.latitude, centre.longitude));

        })
        if(mapRef.current){
            mapRef.current.map.fitBounds(bounds);
            mapRef.current.map.setZoom(8);
        }

    }


      

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info pt-3">
                    <h2 className="text-center text-white">Centres  {localisation}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><LinkUrl to="/">ACCUEIL</LinkUrl>  / </span>
                            <span>{localite&&<LinkUrl to="/centres">CENTRES </LinkUrl>}</span>
                            <span>{!localite&& 'CENTRES'}</span>

                            {localite && <span> / {localite}</span>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mb-3 mt-3">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-2 border-end">
                        <h6 className="secdText border-bottom p-2">LES DEPARTEMENTS</h6>
                        <ul className="faq-cat">
                            {
                                departements.map(dep => (<li><LinkUrl key={dep.id} to={`/centres/${dep.libelle}`} className={`textPrimary ${localite==dep.libelle && 'secdText'}`}>{dep.libelle}</LinkUrl></li>))
                            }

                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 listdescentre">
                        <h6 className="secdText border-bottom p-2 text-uppercase">Centres</h6>
                        {
                            centres.map(centre => (
                                <CentreItem key={centre.id} centre={centre} fixedCentre={fixedCentre}/>

                            ))
                        }


                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <h6 className="secdText border-bottom p-2 text-uppercase">Localiser sur la carte</h6>
                        <div className="card p-2 mt-2 dgishadow">
                            <div style={{ height: '100vh', width: '100%' }}>

                               {showMap && centres &&  <Map
                                    google={google}
                                    ref={mapRef}
                                    initialCenter={
                                        {
                                            lat: userPosition ? userPosition.coords.latitude :6.37,
                                            lng: userPosition ? userPosition.coords.longitude : 2.40
                                        }
                                    }
                                >

                                    {
                                        centres.map(centre => (
                                            <Marker
                                                onClick={(props, marker, e) => onMarkerClick(props, marker, e, centre)}

                                                position={{
                                                    lat: centre.latitude,
                                                    lng: centre.longitude
                                                }}
                                                key={centre.id}
                                                icon={{
                                                    // url: "/img/marker.png",
                                                    anchor: new google.maps.Point(48, 48),
                                                    scaledSize: new google.maps.Size(48, 48)
                                                }}
                                                name={'Current location'} />
                                        ))
                                    }



                                    <InfoWindow
                                        google={google}
                                        onClose={onInfoWindowClose}
                                        marker={activeMarker}

                                        visible={showingInfoWindow}>

                                           { activeMarkerData && <h5 className="card-title"><a href="" className="textPrimary">{activeMarkerData.libelle}</a></h5> }
                                    </InfoWindow>
                                </Map> }


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default GoogleApiWrapper({
    apiKey: (GOOGLE_MAP_API_KEY)
})(Centre)
