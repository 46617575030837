import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';
import FilAriane from '../layout/ariane';
import HelmetMetaData from "../../constants/header";
import { FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";

function CommuniqueItem() {
    const [publication, setPublication] = useState('');
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)


    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'COMMUNIQUES', url: '/communiques' },
        tertiary: { title: publication && publication.titre },

    }

    useEffect(() => {
        getResource(`publications?slug=${slug}`).then(res => {
            setPublication(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
        })


    }, []);

    return (
        <div id="main">
            <HelmetMetaData title={publication && publication.titre}
                description={publication && publication.contenuPublication.substring(0, 500)}
            ></HelmetMetaData>
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{publication && publication.titre}</h2>
                    {publication && <FilAriane ariane={ariane} />}
                </div>
            </div>
            <div className="container mb-3 mt-3">
                <div className="row" style={{ position: 'relative' }}>
                    <div className="col-sm-12 col-md-9 border-bottom-3">
                        <div className="card dgishadow">
                            <div className="d-flex justify-content-between bckColorDark p-3 text-white">
                                <div>
                                    Partagez sur:
                                    <FacebookShareButton
                                        url={`https://impots.bj/actualites/${publication && publication.slug}`}
                                        quote={","}
                                        hashtag={publication && publication.titre}
                                        description={publication && publication.titre}
                                        className="sharesocial"
                                    >
                                        <FacebookIcon size={24} round />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        title={publication && publication.titre}
                                        url={`https://impots.bj/actualites/${publication && publication.slug}`}
                                        hashtags={[publication && publication.titre]}
                                        className="sharesocial"
                                    >
                                        <TwitterIcon size={24} round />
                                    </TwitterShareButton>
                                    <WhatsappShareButton
                                        title={publication && publication.titre}
                                        className="sharesocial"
                                        url={`https://impots.bj/actualites/${publication && publication.slug}`}
                                    >
                                        <WhatsappIcon size={24} round />
                                    </WhatsappShareButton>
                                </div>
                            </div>

                            {/* {publication && publication.media  && <img src={`/${publication.media.contentUrl}`} alt={publication.titre} className="img-fluid"/>} */}

                            <div className="page-content" style={{ position: 'relative', paddingLeft: 30, paddingTop: 30, paddingRight: 30 }}
                                dangerouslySetInnerHTML={{ __html: `${publication && publication.contenuPublication}` }}
                            >

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CommuniqueItem;