import React, { useEffect } from 'react';
import Slider from "react-slick";
import { sliderConfig } from '../../constants';
import LinkUrl from '../../lib/link';

const SliderAccueil = ({home}) => {

    return (  
        <div id="Homeslider" className="container-fluid pt-0">
        <div className="row">

            <div className="slick-slider">
                <div className="slick-slider-init">
                    <Slider {...sliderConfig}>
                        {
                           home && home.actus_slider.map(slider=>(
                                <div className="slick-item" key={slider.id}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 ps-0 pe-0">
                                        <div className="dgi-slide-item w-100 bg-secondary text-white">
                                            <div className="dgi-slide-caption">
                                                <h3 data-animation-in="fadeIn">
                                                    <LinkUrl to={`/actualites/${slider.slug}`}>{slider.titre.length <180 ? slider.titre : slider.titre.slice(0,180)+'...'}</LinkUrl>
                                                   
                                                </h3>
                                                <div className="text-white actu-description mt-4 pl-0" data-animation-in="bounceInUdiv" data-delay-in="1"
                                               
                                             dangerouslySetInnerHTML={{__html:`${slider.contenuPublication.slice(0,200)} ...`}}>
                                                        
                                                   
                                                </div>
                                                <LinkUrl to={`/actualites/${slider.slug}`} className="btn btn-warning btn-lg mt-5 waves-effect waves-light"
                                                        data-animation-in="slideInLeft pr-4">Lire la suite 
                                                    <i className=" mx-3 fas fa-long-arrow-alt-right"></i></LinkUrl >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 ps-0 pe-0 hide4mobile">
                                        <div className="comminqueImg">

                                            {slider.media && <LinkUrl to={`/actualites/${slider.slug}`} ><img src={process.env.REACT_APP_API_BASE_URL + '/'  + slider.media.contentUrl} alt="" className="img-fluid w-100 h-100"/></LinkUrl>}
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            ))
                        }
        
                    </Slider>
   

                </div>
            </div>
        </div>
    </div>
    );
}
 
export default SliderAccueil;