import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import FilAriane from '../layout/ariane';
import FaqCategorie from './categ_faq';
import FaqThemes from './theme';
import _ from "lodash"
import LinkUrl from '../../lib/link';
function Faq() {
    const [themes, setThemes] = useState([]);
    const [current_theme, setCurrentTheme] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [searchFaqs, setSearchFaqs] = useState([]);
    const [currentFaq, setCurrentFaq] = useState(null);

    const { slug, question } = useParams()
    useEffect(() => {
        if(!question)
        loadFaqs()
        else 
        searchFaqQuestion()
    }, [slug]);

    useEffect(() => {
        if (search)
            searchFaq()
    }, [search]);

    const loadFaqs = () => {
        getResource(`categories_faqs?${slug && `libUnique=${slug}`} `).then(res => {
            setThemes(res.data['hydra:member'])
            if (slug && res.data['hydra:member'].length == 1) {
                setCurrentTheme(res.data['hydra:member'][0])
            }
        })
    }

    const searchFaq = () => {
        getResource(`faqs?${search && `libelleQestion=${search}`} `).then(res => {
            var result = _(res.data['hydra:member'])
                .groupBy(x => x.categorie.libelle)
                .map((value, categorie) => ({ categorie: categorie, faqs: value }))
                .value();
            setSearchFaqs(result)
        })
    }

    const searchFaqQuestion = () => {
        getResource(`faqs?${question && `libelleQestion=${question}`} `).then(res => {
            if(res.data['hydra:member'].length>0){
                setCurrentFaq(res.data['hydra:member'][0])
                setCurrentTheme(res.data['hydra:member'][0].categorie)
            }
           
        })
    }





    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info mb-5">
                    <h2 className="text-center text-white">Foire aux questions</h2>

                    <div className="searchContainer-simple">
                        <div className={`search ${openSearch && 'open'}`}>
                            <div className="icon" onClick={() => setOpenSearch(true)}>
                                <span>
                                    <svg viewBox="0 0 40 40">
                                        <path d="M3,3 L37,37"></path>
                                    </svg>
                                </span>
                            </div>
                            <div className="field">
                                <input type="text" placeholder="Votre recherche ici" onChange={(event) => setSearch(event.target.value)} />
                            </div>

                        </div>
                        <div class="card searchResult lesFaq" style={{ display: search && openSearch && 'block' }}>
                            {
                                searchFaqs.map(search_faqs => (
                                    <div key={search_faqs.id} class="border-bottom mb-1 pb-1 faq-group">
                                        <h5>{search_faqs.categorie}</h5>
                                        <ul className="list-group">
                                        {
                                            
                                            search_faqs.faqs.map(sf => (
                                                <li key={sf.id} >
                                                    <LinkUrl class="list-group-item secdText " to={`/foire_aux_questions/${sf.categorie.libUnique}/${sf.libelleQestion}`}>
                                                    {sf.libelleQestion}
                                                    </LinkUrl>
                                                </li>
                                            ))
                                        }

                                        </ul>

                                    </div>
                                ))
                            }

                        </div>

                    </div>
                    <div className="bread-crumb" style={{ bottom: -28 }}>
                        <div className="container">
                            <span><LinkUrl to="">ACCUEIL </LinkUrl> / </span>
                            {!current_theme && <span className="text-uppercase"> Foire aux questions  </span>}
                            {current_theme && <span className="text-uppercase"><LinkUrl to="/foire_aux_questions"> Foire aux questions </LinkUrl> /</span>}
                            {current_theme && !currentFaq && <span className="text-uppercase"> {current_theme.libelle} </span>}
                            {current_theme && currentFaq && <span className="text-uppercase"> <LinkUrl to={`/foire_aux_questions/${current_theme.libUnique}`}> {current_theme.libelle} </LinkUrl> </span>}
                            {question && <span className="text-uppercase"> / {question} </span>}

                        </div>
                    </div>

                </div>


            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 border-end">
                        <FaqThemes slug={slug} />
                    </div>
                    <div className="col-md-8">
                        <div className="card p-2">
                            <div className="lesFaq">
                                {
                                    !question && themes && themes.map(cf => (
                                        <FaqCategorie key={cf.id} cf={cf} />
                                    ))
                                }

                                {
                                    question && currentFaq && <p className="mb-3 justify-content-around" dangerouslySetInnerHTML={{ __html: currentFaq.reponse }} ></p>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Faq;