import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'
import moment from "moment"
import 'moment/locale/fr'
import { ITEM_PER_PAGE } from '../../constants';
import FilAriane from '../layout/ariane';
import LinkUrl from '../../lib/link';
import HelmetMetaData from "../../constants/header";
import { FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
moment.locale('fr')

function PublicationsAvisCommuniques() {

    const [publications, setPublications] = useState([]);
    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])


    useEffect(() => {
        loadData()
    }, [search]);

    useEffect(() => {
        loadData()
    }, [currentPage]);

    const loadData = () => {
        getResource(`publications?categorie.libUnique=communique&itemsPerPage=${ITEM_PER_PAGE}&page=${currentPage}${search ? `&titre=${search}` : ''}`).then(res => {
            setPublications(res.data['hydra:member'])
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: 'COMMUNIQUES' }
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Avis et communiqués</h2>
                    <FilAriane ariane={ariane} />

                </div>


            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">

                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>

                        <div className="icon" title="Cliquez pour Rechercher">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                    <div className="x-scroll">
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div>
                </div>
                <div className="row mt-3">
                    {publications && publications.map(act => (
                        <div key={act.id} className="actu-items col-sm-12 col-lg-4 mt-3">
                            <HelmetMetaData title={act && act.titre}
                                description={act && act.contenuPublication.substring(0, 500)}
                            ></HelmetMetaData>
                            <div className="single-actu-item">
                                <div className="actu-image">
                                    <LinkUrl to={`/actualites/${act.slug}`}>
                                        {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt="news 1"
                                            className="img-fluid" />}
                                        {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                            className="img-fluid" />}
                                    </LinkUrl>
                                    <div className="shareBtn">
                                        <div className="shareBtn-content">
                                            <FacebookShareButton
                                                url={`https://impots.bj/actualites/${act && act.slug}`}
                                                quote={","}
                                                hashtag={act && act.titre}
                                                description={act && act.titre}
                                                className=" sharesocial_ social-icons"
                                            >
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                title={act && act.titre}
                                                url={`https://impots.bj/actualites/${act && act.slug}`}
                                                hashtags={[act && act.titre]}
                                                className=" sharesocial_ social-icons"
                                            >
                                                <TwitterIcon size={32} round />
                                            </TwitterShareButton>
                                            <WhatsappShareButton
                                                title={act && act.titre}
                                                className="  sharesocial_ social-icons"
                                                url={`https://impots.bj/actualites/${act && act.slug}`}
                                            >
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                        </div>
                                        <span className="z-depth-2"><i className="fas fa-share-alt"></i></span>
                                    </div>
                                    <div className="actu-meta">
                                        <p>{moment(act.createdAt).format('DD-MM-YYYY')}</p>
                                    </div>
                                </div>
                                <div className="actu-text">
                                    <LinkUrl to={`/actualites/${act.slug}`}>
                                        <h3 className="text-uppercase">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre}</h3>
                                    </LinkUrl>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

        </div>
    )
}

export default PublicationsAvisCommuniques;