import React, { useEffect, useState } from 'react';
import { getResource } from '../../services/api';
import Actualites from './actualites';
import Contact from './contact';
import EcheanceFiscale from './echeance_fiscale';
import Regime from './regime';
import ServiceUsager from './service_usager';
import ServiceUsuel from './service_usuel';
import SliderAccueil from './slider_accueil';

const Home = () => {
    const [home, setHome] = useState(null);
    useEffect(()=>{
        getResource(`home`).then(res=>{
            setHome(res.data)
        })
    },[])
    return (
        <>
            <Regime home={home}  />
            <SliderAccueil home={home}/>
            <ServiceUsuel home={home} />
            <ServiceUsager home={home}/>
            <EcheanceFiscale  home={home}/>
            <Contact  home={home}/>
            <Actualites home={home}/>
        </>
    );
}

export default Home;