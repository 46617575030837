import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LinkUrl from '../../lib/link';
import { urlGenerator } from '../../lib/url';
import { getResource } from '../../services/api';
import Actualites from '../home/actualites';
import FilAriane from '../layout/ariane';
import ContribuableActualites from './actualites';
import _ from 'lodash'

function Contribuable() {

    const [regimeMenu, setRegimeMenu] = useState(null);
    const [home, setHome] = useState(null);
    const { slug } = useParams()

    useEffect(() => {
        getResource(`menus?slug=${slug}`).then(res => {
            setRegimeMenu(res.data['hydra:member'][0])
        })

        getResource(`home`).then(res => {
            setHome(res.data)
        })
    }, [slug]);

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: regimeMenu && regimeMenu.name }
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">{regimeMenu && regimeMenu.name}</h2>
                    {regimeMenu && <FilAriane ariane={ariane} />}
                </div>
            </div>
            <div className="container mb-3 mt-3">
                {
                    regimeMenu && _.sortBy(regimeMenu.enfants, ['position']).map(re => (
                        <div key={re.id} >
                            <h4 key={re.id} class="mb-2 mt-4">{re.name}</h4>
                            <div class="row">
                                {
                                    re.enfants.map(res => (
                                        <div key={res.id} class="col-md-4 col-sm-12 ">
                                            <LinkUrl to={urlGenerator(res)} class="block-wrapper">
                                                <div class="block-title" title={res.name}>{res.name.substring(0, 1).toUpperCase()}{res.name.length > 50 ? `${res.name.substring(1, 50).toLowerCase()}...` : res.name.substring(1, 50).toLowerCase()} </div>
                                                <div class="block-icon"><i class="fas fa-link"></i></div>
                                            </LinkUrl>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <ContribuableActualites slug={slug} />
        </div>
    )
}
export default Contribuable;